@import '~@admobilize/ui-kit/variables';

.chiptags {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #455079;
  border-radius: 5px;
  padding: 8px;

  &.disabled {
    border-color: transparent;
  }

  input {
    border: none;
    outline: none;
    margin-top: 4px;
  }
}
