@import '~@admobilize/ui-kit/variables';

$header-height: 88px;

.profile-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;

  height: calc(100vh - #{$header-height});
  padding: 12px;

  > div {
    width: 49.5%;
  }

  .card {
    height: 100%;
    padding-bottom: 12px;

    .card-header {
      display: flex;
      align-items: center;
      background-color: white;
      padding: 12px 20px;

      img {
        height: 20px;
        margin-right: 10px;
      }

      h3 {
        line-height: inherit;
        font-weight: bold;
        font-size: 18px;
        color: #455079;
        margin: 0;
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      overflow-y: auto;
      min-height: auto;
      padding: 12px;

      .section {
        width: 100%;

        .section-title {
          font-weight: bold;

          background-color: $brand-grey-light;
          border-radius: 5px;

          padding: 6px 12px;
          margin-bottom: 12px;
        }

        .section-content {
          padding: 0 12px 12px 12px;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    flex-direction: row;
    /**
     * 100vw: screen width
     * 60px: navbar width
     * 24px: container left & right paddings
     */
    width: calc(100vw - 60px);
    overflow-y: auto;

    > div {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
}