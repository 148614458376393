#manage-users {
  .search-user {
    border-bottom: 1px solid #EFEFEF;
    margin-bottom: 25px;
    padding-bottom: 25px;

    .search-box {
      button {
        padding: 0 6px;
      }
    }
  }

  .custom-dropdown {
    .dropdown-list-container {
      width: 100%;
      margin-right: 0;

      button {
        width: 100%;
      }
    }
  }

  h3 {
    line-height: inherit;
    font-weight: bold;
    font-size: 20px;
    color: #455079;
    margin-bottom: 30px;
  }

  .btn-impersonate {
    margin-right: 1rem;
  }
}