@import '~@admobilize/ui-kit/variables';

.device-input-section {
  h4 {
    font-size: 16px;
    color: $text-dark;
    margin-bottom: 12px;
    font-weight: bold;
  }

  section {
    &:not(:last-child) {
      padding-bottom: 4px;
      margin-bottom: 24px;
      border-bottom: 1px solid #CFD5E6;
    }
  }

  .tab-label {
    padding: 8px 0px 8px 12px;
    gap: 10px;
    background: #EDEFF5;
  }

  .section-container {
    margin-top: 24px;
    padding-left: 12px;

    &:last-child {
      margin-bottom: 55px;
    }
  }

  .section-title {
    h3 {
      font-size: 22px;
      margin-bottom: 24px;
    }

    h4 {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 24px;
      color: #2F64F5;
    }
  }
}