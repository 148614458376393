.impersonate-mode {
  display: flex;
  justify-content: center;

  .imp-border {
    position: absolute;
    background: #FFA30F;
    width: calc(100vw - 76px);
    height: 12px;
    right: 0;

    &.top {
      top: 0;
    }

    &.bottom {
      bottom: 0;
    }

    &.left {
      top: 0;
      left: 76px;
      width: 12px;
      height: 100%;
    }

    &.right {
      top: 0;
      width: 12px;
      height: 100%;
    }
  }

  .imp-tools {
    display: flex;
    align-items: center;
    position: absolute;
    width: 412px;
    bottom: 20px;
    background: #FFA30F;
    color: white;
    padding: 12px 24px;
    border-radius: 5px;
    right: calc(50vw - 38px - 206px);// half screen, half navbar, half toolbar
    transition: bottom 100ms ease-out;
    z-index: 15;

    .eyes-icon {
      width: 20px;
      height: 20px;
      margin-right: 12px;
      filter: brightness(100);
    }

    .impersonating-name {
      margin-right: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .icon-button {
      margin-left: 8px;
    }

    :hover {
      bottom: 24px;
    }
  }

  #change-user, #leave-impersonation {
    background: #FFF4DB;

    img {
      filter: invert(60%) sepia(100%) saturate(1018%) hue-rotate(351deg) brightness(104%) contrast(101%);
    }

    &:hover {
      background: #EDE3CC;
    }
  }
}

.impersonate-modal-body {
  .close-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;

    img {
      width: 18px;
      height: 18px;
    }
  }

  .content-wrapper {
    padding: 0 18px 18px;

    .search-box {
      margin-top: 8px;
      margin-bottom: 24px;
      border: 2px solid #2f64f5;
    }
  }

}