@import '~@admobilize/ui-kit/variables';

.status-counters {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 450px;

  .status-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33%;

    color: $brand-slate-blue;

    &:nth-of-type(2) {
      border-left: 1px solid $brand-grey;
      border-right: 1px solid $brand-grey;
    }

    .status-counter-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.75rem;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .status-counter-label {
      margin-right: 0.5rem;
    }

    b {
      font-size: 18px;
    }
  }
}
