@import '~@admobilize/ui-kit/variables';

$footer-height: 51px;

/*
  - header height: 92px
  - toolbar: 91px
  - sidepanel padding: 24px;
  - searchbox margin: 12px;
  - searchbox height: 43px;
 */
$page-offset: 183px;
$toolbar-offset: 55px;
$sidepanel-padding: 24px;
$tabs-padding: 48px;

$sidepanel-height: $page-offset + $toolbar-offset + $sidepanel-padding + $tabs-padding;

.advanced-container {
  display: flex;
  flex-direction: column;

  .toolbar {
    .count {
      margin-right: 12px;
    }

    .selected-device {
      display: flex;
      gap: 4px;
      padding-left: 12px;
      margin-right: 24px;
      align-items: center;
      border-left: 1px solid #dae0f2;
      color: $brand-slate-blue-medium;
      font-size: 18px;
    }

    .selected-device-name {
      color: $brand-blue-medium;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .advanced-content {
    display: flex;
    padding: 0px 32px 0 0;

    .input-text-ellipsis {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .device-list {
      .item-selector-container {
        height: calc(100vh - #{$sidepanel-height});
        margin-top: 12px;

        .search-box {
          margin-bottom: 20px;
          border-bottom: 1px solid $brand-grey-light;
        }

        .item-selector-list {
          width: 100%;
          background: white;
          padding: 0;

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            img {
              margin-right: 0px;
            }
          }
        }
      }

      .no-items {
        display: block;
        margin-top: 12px;
        color: $text-dark;
      }

      .search-filter-bar {
        display: flex;
        justify-content: space-between;
        > .search-box {
          width: 100%;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: center;
      height: $footer-height;
    }

    &.side-panel-closed {
      .edit-button-container {
        /* 100% - main navigation - container padding - tab padding - scrollbar */
        width: calc(100% - 76px - 76px - 96px - 6px);
      }
    }

    .side-panel {
      height: calc(100vh - #{$page-offset} - 20px);
    }
  }

  .edit-button-container {
    bottom: 56px;
  }
}

.user-modal.modal-dialog {
  max-width: 650px;

  @media (max-width: 992px) {
    max-width: 90%;
  }
}
