/*
 * 92px: app-header height (with top & bottom padding)
 * 91px: toolbar height (with top & bottom padding)
 * 12px: devices-container with bottom padding
 * 72px: thead hight
 * 46px: pagination-wrapper height with top padding
 * TOTAL = 313px -> table offset
 */
$table-offset: 313px;

/*
 * 92px: app-header height
 * 91px: toolbar height
 * 12px: devices-container bottom-padding
*/
$page-offset: 195px;

/*
 * 55px: filter header
 * 88px: applied filters
 * 24px: side panel padding
 * 24px: separator margin
*/
$filter-offset: 191px;

/*
 * 24px: side-menu top & bottom padding
 * 51px: side-menu header height (with top & bottom padding and bottom margin)
 * 12px: side-menu header margin-bottom
 * TOTAL = 87px -> accordion offset
 */
$details-offset: 87px;
$details-height: 60px;
$details-sections: 3;

.devices {
  .empty-device-table {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .no-data-message {
    height: calc(100vh - 183px);
  }

  .devices-container {
    display: flex;
    padding: 0 24px 0 0;
    width: auto;

    .side-panel {
      .side-panel-container {
        height: calc(100vh - #{$page-offset});
      }
    }

    .split-view {
      margin-right: 24px;
    }

    .action-buttons {
      .commands-list {
        display: flex;
        align-items: center;

        .icon-button {
          margin-left: 12px;
        }
      }
    }

    .react-table {
      &.slim-table {
        table {
          thead tr th,
          tbody tr td {
            &:nth-child(1) {
              width: auto;
            }

            &:nth-child(4),
            &:nth-child(5) {
              display: none;
              width: 0px;
            }
          }
        }
      }

      table {
        thead tr th,
        tbody tr td {
          &:nth-child(1) {
            //Name
            width: auto;
            text-align: left;
            padding-left: 24px;
          }
          &:nth-child(2) {
            //Solution
            width: 110px;
            div {
              display: flex;
              justify-content: center;
            }
          }
          &:nth-child(3) {
            //Status
            width: 150px;
            div {
              display: flex;
              justify-content: center;
            }
          }
          &:nth-child(4) {
            //Last Detection
            width: 200px;
          }
          &:nth-child(5) {
            //Version
            width: 140px;
          }
          &:nth-child(6) {
            //Id
            width: 250px;
            text-align: left;
          }
        }

        tbody {
          /*
          * 100vh: screen viewport height
          * 229px: table offset
          */
          height: calc(100vh - #{$table-offset});

          tr td {
            &:nth-child(7) {
              //Id
              width: 244px;
            }
            .ellipsis {
              flex-wrap: wrap;
              overflow: hidden;

              div {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }

    .pagination-wrapper {
      width: 100%;
      justify-content: center;
      .footer {
        min-width: 280px;
        width: auto;
      }
    }
  }

  .device-icon {
    margin: 0 12px;
  }

  .solution-icon {
    width: 30px;
    height: 30px;
  }

  .column-header {
    padding: 17px 15px;
  }

  #device-details-accordion {
    /*
     * 100vh: screen viewport height
     * 187px: accordion offset
     */
    height: calc(100vh - #{$page-offset} - #{$details-offset});
  }

  #device-details-accordion {
    .accordion-item {
      .item-body {
        position: relative;
        padding: 0px 12px 0px 0px;

        .input-text-ellipsis {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      &.is-expanded {
        & > .item-body {
          /*
            * 100vh: screen viewport height
            * 255px: accordion offset
            * 60px: accordion-item with bottom margin
            * $accordion-number-of-items: number of accordion-item items
            * 12px: last item margin-bottom removed
            */
          height: calc(
            100vh - #{$page-offset} - #{$details-offset} - (#{$details-height} * #{$details-sections}) + 12px
          );
          width: auto;
          padding-top: 24px;

          .device-input-section {
            .row {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
