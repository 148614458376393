@import '~@admobilize/ui-kit/variables';
.period-selector {
  .DateRangePicker {
    display: flex;
    input {
      width: 100px;
      overflow: visible;
      border: none;
      color: #455079;
      padding: 0px 5px;
      outline: none;
      background-color: transparent;

      &:focus {
        background: white;
        border-radius: 5px;
      }
    }

    .hifen {
      align-self: center;
      width: 14px;
    }

    .uil.uil-schedule {
      font-size: 1.2rem;
      color: #455079;
      padding: 0px 5px;
    }
  }
}
