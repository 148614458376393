@import "~@admobilize/ui-kit/variables";

.modal #preset-modal {
  .modal-content {
    color: $brand-slate-blue;

    .modal-header.header-preset {
      padding-bottom: 0px;
      border-bottom: none;

      .modal-title {
        font-size: 22px;
      }
    }

    .modal-footer {
      padding: 30px;
      border-top: none;

      .btn {
        font-weight: bold;
      }

      .btn-secondary.outline {
        border: 2px solid $brand-blue-medium;
        color: $brand-blue-medium;
        background-color: #f2f4f9;

        &:active,
        &:hover,
        &:focus {
          background-color: $brand-blue-light;
        }
      }
    }

    .subtitle-modal {
      color: $text-dark;
      font-size: 16px !important;
      padding: 0 30px 30px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .compare-object-table {
      max-height: 50vh;
      overflow-y: auto;
      overflow-x: hidden;

      .table-title {
        cursor: default;
        background-color: #eef3fe;
        padding: 12px 30px 13px;
        font-weight: bold;

        .info-icon {
          margin-left: 8px;
        }
      }

      .table-session {
        &:not(:first-child) {
          margin-top: 12px;
        }

        .no-changes-session {
          padding: 12px 30px 13px;
          text-align: center;
        }

        .table-session-title {
          background-color: #f6f9fe;
          padding: 12px 30px 13px;
          font-weight: bold;
        }

        .table-session-subtitle {
          font-weight: bold;
          padding: 12px 30px 13px;

          .badge {
            padding: 2px 4px;
            font-weight: 600;
            border-width: 2px;
            margin-left: 8px;
          }
        }

        .table-session-body {
          padding: 0px 30px;
          margin-left: 20px;
          word-wrap: break-word;

          .table-row {
            padding: 8px 0px 8px;

            .new-value {
              color: #767E9A;
            }

            .old-value {
              color: #767E9A;
            }
          }
        }
      }
    }

    .section-title {
      h3 {
        font-size: 22px;
        margin-bottom: 0px;
      }

      h4 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 0px;
        color: #2F64F5;
      }
    }
  }

  @media(max-width: 992px) {
    max-width: 650px;
  }
}