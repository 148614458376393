@import '~@admobilize/ui-kit/variables';


.item-notification {
  padding: 12px;
  border-radius: 5px;
  margin-left: 12px;
  margin-right: 12px;

  &:first-child {
    margin-top: 12px;
  }

  &:last-child {
    margin-bottom: 12px;
  }

  .actions {
    display: none;
    margin-left: 8px;

    .icon-button.btn {
      width: 15px;
      height: 15px;
      display: flex;

      &:not(:first-child) {
        margin-left: 5px;
      }

      img {
        width: 14px;
        height: 14px;
      }
    }
  }

  .icon-item-notification {
    display: flex;
    padding: 10px;
    height: 48px;
    width: 48px;
    border-radius: 5px;
    background-color: #E2EDFF;

    &.success {
      background-color: $status-success-light;
    }

    &.error {
      background-color: $status-danger-light;
    }

    &.warn {
      background-color: #fff4db;
    }
  }

  .notification-info {
    margin-left: 8px;
    color: #767E9A;

    .notification-service {
      margin: 4px 0px;
      font-size: 10px;
    }

    .notification-title {
      font-size: 18px;
      color: #455079;
      font-weight: bold;
      margin-bottom: 8px
    }

    .notification-description {
      margin-bottom: 8px;
      line-height: normal;
      overflow-wrap: break-word;
      max-width: 250px;
    }

    .notification-time {
      display: flex;

      i, small {
        font-size: 10px;
      }

      i {
        margin-right: 3px;
      }
    }

    .badge-pill {
      padding: 2px 4px;
      font-weight: bold;
      font-size: 10px;
    }
  }

  &:hover {
    background-color: #F5F7FA;

    .actions {
      display: flex;
    }
  }
}