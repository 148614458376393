@import "~@admobilize/ui-kit/variables";

#notification-tools {
  .tabs {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .tabs-header {
      padding: 0px;
      background: none;

      & > div {
        display: flex;
        flex: 1;

        & > button {
          display: flex;
          flex: 1;
          justify-content: center;
          font-weight: bold;
          border-bottom: 4px solid #CFD5E6;


          &.btn-tab.selected {
            color: #2F64F5;
            border-bottom: 4px solid #2F64F5;
            margin-bottom: 0px;
          }
        }
      }
    }

    .tabs-body {
      display: flex;
      flex: 1;
      padding: 0px;
      margin: 0px;
      overflow: hidden;

      .settings-tab {
        margin: 20px;

        h3 {
          color: #455079;
          font-size: 18px;
          font-weight: bold;
          margin: 0px;
        }

        p {
          color: #767E9A;
          font-size: 16px;
        }
      }
    }
  }
}
