@import '~@admobilize/ui-kit/variables';

.item-selector-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .item-selector-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;

    border-top: 1px solid $brand-grey-medium;
    padding-top: 20px;
    padding-bottom: 16px;
    color: $brand-slate-blue-medium;

    .sort-button {
      cursor: pointer;

      label {
        cursor: pointer;
        margin-bottom: 0;
      }
    }
  }

  .item-selector-list {
    height: 100%;
    overflow-y: auto;
    list-style: none;

    margin-bottom: 0;
    padding: 8px;
    border-radius: 5px;

    background-color: $brand-grey-light;
    color: $brand-slate-blue;

    li {
      display: flex;
      align-items: center;

      padding: 8px 14px;
      margin-bottom: 4px;
      cursor: pointer;

      &:hover {
        background-color: $brand-blue-light;
      }

      &.selected {
        background-color: $brand-blue-light;
        color: $brand-blue-medium;
        border-radius: 5px;
      }

      img {
        margin-right: 10px;
      }

      div {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .selector-list-option {
        padding: 8px;
      }
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-top: 20px;
  }
}