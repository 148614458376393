@import "~@admobilize/ui-kit/variables";

#notifications {
  padding: 24px;
  height: calc(100vh - 136px);

  .notification-container {
    display: flex;

    .notification-menu {
      display: flex;
      flex-direction: column;
      width: 200px;
      color: $brand-slate-blue;
      margin-right: 12px;

      .filter-block {
        &:not(:last-child) {
          margin-bottom: 25px;
        }

        .custom-dropdown {
          display: flex;
          flex: 1;

          .multiselect {
            display: flex;
            flex: 1;

            .dropdown-menu {
              min-width: 100%;
            }
          }
        }

        .only-urgent-actions {
          border-top: 1px #CFD5E6 solid;
          justify-content: center;
          display: flex;
          padding: 12px 24px 0px 24px;

          .custom-switch {
            padding-top: 6px;
            padding-bottom: 6px;
          }
        }

        .tag {
          &:first-child {
            margin-top: 10px;
          }
          margin-top: 5px;
        }
      }

      .menu-title {
        font-size: 18px;
      }

      .menu-item {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        button:hover, &.selected button {
          border: 2px solid #3767EC;
          box-shadow: 0px 9px 15px #07165826;
        }

        button {
          font-weight: bold;
          font-size: 16px;
          color: #455079;
          background-color: white;
          box-shadow: 0px 3px 9px #1732A90D;

          &:focus {
            background-color: white
          }

          i.uil {
            margin-right: 7px;
          }
        }
      }

      .remove-notification {
        display: flex;
        justify-content: center;

        button {
          font-weight: bold;
          padding-left: 30px;
          padding-right: 30px;
          margin-right: 12px;
          display: flex;
          flex: 1;
          justify-content: center;
        }
      }
    }

    .notifications-list {
      display: flex;
      background-color: white;
      border-radius: 5px;
      box-shadow: 0px 1px 3px #0000002E;
      flex: 1;
      flex-direction: column;
      overflow-y: auto;

      .no-notification {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h3 {
          margin-top: 20px;
        }

        p {
          color: #767E9A;
        }
      }
    }
  }
}
