@import '~@admobilize/ui-kit/variables';

.heatmap-container {
  flex: 1 1;
  width: 100%;
  min-height: 500px;

  .card {
    height: 100%;

    .card-body {
      justify-content: normal;
      align-items: normal;
      flex-direction: column;
    }
  }

  .heatmap--header {
    display: flex;
    justify-content: space-between;
    padding: 12px 0 24px;

    #heatmap--legend {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .legend--header {
        display: flex;
      }

      .legend--label {
        font-size: 14px;
        display: flex;
        align-items: center;
        font-weight: bold;
      }
    }

    .info-box-wrapper {
      position: relative;
      margin-left: 4px;

      .info-button {
        height: 20px;
        width: 20px;

        img {
          height: 15px;
          width: 15px;
        }
      }

      .info-box {
        top: 30px;
      }
    }

    .heatmap--legend {
      padding-right: 4px;
      .heatmap--legend-bar {
        width: 300px;
        height: 16px;
        margin: 7px 0;
        background: linear-gradient(
          90deg,
          #060869 0%,
          rgba(30, 198, 218, 1) 40%,
          rgba(17, 181, 61, 1) 60%,
          rgba(208, 246, 45, 1) 70%,
          rgba(230, 235, 19, 1) 80%,
          rgba(255, 0, 0, 1) 100%
        );
      }

      .heatmap--legend-values {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: bold;
        color: $text-dark;

        .heatmap--legend-max,
        .heatmap--legend-min {
          font-size: 12px;
          font-weight: normal;
        }
      }
    }

    .heatmap--filters {
      display: flex;
      align-items: center;
    }
  }

  .heatmap--canvas {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $background;
    position: relative;

    img {
      object-fit: none;
      position: absolute;
      z-index: 0;

      &.loaded {
        object-fit: initial;
      }
    }

    canvas {
      z-index: 0;
    }

    .component-loading {
      position: absolute;
      z-index: 2;
      width: 50%;
    }
  }
}
