@import "~@admobilize/ui-kit/variables";

.model-configuration {
  display: flex;
  flex-direction: column;

  padding-top: 25px;
  border-top: 1px solid $brand-grey-light;

  .model-configuration-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 12px;

    span {
      font-weight: bold;
    }
  }

  .model-configuration-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .form-group {
      width: 48%;
      margin-bottom: 12px!important;

      &:nth-last-child(-n+2) {
        margin-bottom: 0!important;
      }

      .custom-dropdown .dropdown-list-container {
        margin-right: 0;
        width: 100%;

        button {
          width: 100%;
        }
      }
    }
  }
}