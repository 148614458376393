@import '~@admobilize/ui-kit/variables';

#login-screen {
  .left-area {
    .signup-header {
      margin-bottom: 48px;
    }

    .form-box {
      width: 80%;
    }

    .signup-form {
      margin-bottom: 32px;

      .form-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 0 12px 0;
      }

      .custom-input {
        min-height: 39px;
      }
    }

    .confirm-button {
      justify-content: center;
    }
  }
}

.signup-header {
  .email {
    color: $brand-blue-medium;
  }
}
