@import '~@admobilize/ui-kit/variables';

.wizard {
  display: flex;
  padding: 24px;

  h2 {
    font-size: 22px;
    margin: 0;
    color: $text-dark;
    font-weight: bold;
  }

  h3 {
    font-size: 18px;
    margin: 0;
    color: $text-dark;
    font-weight: bold;
  }

  h4 {
    font-size: 16px;
    margin: 0;
    color: #767e9a;
  }

  .wizard-navigation {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    padding-right: 32px;

    .wizard-header {
      display: flex;

      img {
        margin-right: 12px;
      }
    }

    .wizard-step {
      border: 1px solid #cfd5e6;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 8px;
      margin-top: 12px;
      cursor: pointer;

      .wizard-step-content {
        display: flex;
        align-items: center;
      }

      .wizard-step-number {
        width: 37px;
        height: 37px;
        font-size: 22px;
        background-color: #ebedf2;
        color: $text-dark;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        margin-right: 12px;
        border-radius: 5px;
      }

      &.default {
        &:hover {
          background-color: white;
          border: 1px solid white;
          box-shadow: #1732a90d 0px 3px 9px;

          &::after {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-left: 12px solid white;
            right: 21px;
          }
        }
      }

      &.selected {
        border: 3px solid $brand-blue-medium;
        background-color: white;

        &::before,
        &::after {
          content: '';
          width: 0;
          height: 0;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
          border-left: 12px solid $brand-blue-medium;
          position: absolute;
          right: 21px;
        }

        &::after {
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 10px solid white;
          right: 25px;
        }

        .wizard-step-number {
          background-color: $brand-blue-medium;
          color: white;
        }
      }

      &.completed {
        border: none;
        background-color: white;
        justify-content: space-between;
        box-shadow: #1732a90d 0px 3px 9px;

        .wizard-step-number {
          background-color: $brand-blue-medium;
          color: white;
        }
      }

      &.blocked {
        cursor: not-allowed;
      }
    }
  }

  .wizard-content-container {
    padding: 0;

    .wizard-content {
      background-color: white;
      padding: 32px;
      border-radius: 5px;
      box-shadow: #0a1b6512 0px 3px 5px;

      .wizard-content-description {
        margin-bottom: 24px;
      }

      .step-content {
        max-height: calc(100vh - 388px);
        overflow-y: auto;
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        padding-top: 12px;
        margin-top: 24px;
        border-top: 1px solid #cfd5e6;

        .btn {
          font-weight: bold;
        }
        .btn-primary {
          &:active,
          &:focus {
            background-color: #2f64f5;
            border-color: #2f64f5;
          }
        }
      }
    }
  }
}
