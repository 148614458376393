@import '~@admobilize/ui-kit/variables';

.content {
  .modal-body {
    width: 100%;

    .settings-tab {
      overflow-y: auto;
    }
  }
}

.notification-settings {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  width: 100%;

  .accordion {
    height: 100%;
    .accordion-item {
      &.is-expanded {
        height: calc(100% - 58px);
        .item-body {
          height: 100%;
          padding-bottom: 24px;
          max-height: calc(100vh - 215px);

          .tag:not(:last-child) {
            margin-right: 0px;
          }

          .chiptags {
            display: flex;
            flex-direction: column;
          }

          .invalid-feedback {
            display: block;
          }

          .email-options {
            .email-option-header {
              margin-bottom: 8px;
            }
            .email-option-main {
              margin-bottom: 8px;
            }
          }
        }
      }
      .item-header {
        padding: 6px 12px;
      }
    }
  }

  .options {
    .option-header {
      margin-bottom: 15px;
    }

    .option-input-line {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .option-title {
      font-size: 16px;

      &.bold {
        font-weight: bold;
      }
    }

    .option-input {
      display: flex;
      justify-content: center;
      align-items: center;

      .custom-checkbox {
        width: 24px;
        height: 24px;
        margin-left: 0px;
        margin-top: 0px;
        border: 2px solid #455079;
        background: white;
        justify-content: center;
        align-items: center;

        &.selected {
          background-color: #2f64f5;
          border-color: #2f64f5;
        }

        .uil-check:before {
          font-size: 20px;
          margin: 0;
          font-weight: bold;
        }
      }
    }
  }
}
