@import "~@admobilize/ui-kit/variables";

.offcanvas-drawer {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 50;
  background-color: white;
  height: 100vh;
  width: 400px;
  color: #455079;
  box-shadow: 0px 3px 5px #07165826;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 30px 14px 14px;

    .modal-header {
      display: flex;
      padding: 0px;
      border-bottom: none;

      h3 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 0px;
      }

      p {
        color: #767E9A;
      }

      #close-modal {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 15px;
          height: 15px;
        }
      }
    }

    .modal-body {
      display: flex;
      flex: 1;
      padding: 0px;
      overflow: hidden;
    }
  }


  &.opened {
    display: flex;
  }
}