.icon-button {
  &.btn {
    padding: 0;
    width: 30px;
    height: 30px;

    img {
      width: 26px;
      height: 26px;
    }

    &:not(:disabled) {
      &:hover {
        background:#E2EDFF;

        img {
          // The original icon should be #455079 so after applying the filter
          // it will become #326df5 the closest we got from #2F64F5
          filter: saturate(2.7) brightness(1.32) hue-rotate(354deg);
          cursor: pointer;
        }
      }
    }
  }

  &.small {
    img {
      width: 20px;
      height: 20px;
    }
  }

  &.smallest {
    img {
      width: 12px;
      height: 12px;
    }
  }
}