@import '~@admobilize/ui-kit/variables';

.search-box {
  &.input-gray, &.input-gray input {
    background-color: $brand-grey-medium;
  }

  &.input-gray {
    padding: 0px;
  }

  &.underline {
    background-color: #FFFFFF00;
    border-bottom: 2px solid #76819e;
    border-radius: 0;
    padding: 0;
    box-shadow: none;
    margin-bottom: 10px;
    padding-bottom: 5px;

    input {
      background-color: #FFFFFF00;
      padding: 0;
      font-size: 14px;
    }
  }

  input {
    min-width: auto;
  }

  button {
    height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }
}