@import '~@admobilize/ui-kit/variables';

.custom-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $text-dark;

  .copy img {
    height: 16px;
  }
}

.input-wrapper {
  display: flex;
  align-items: center;

  img {
    height: 20px;
  }
}
