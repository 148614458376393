#drawer {
  &.hidden {
    padding: 0!important;
  }

  &.side-menu {
    background: white;
    box-shadow: 0px 2px 6px #00000012;
    overflow-x: unset;
    padding: 12px;
  }

  .details-drawer-header {
    padding: 12px;
    margin-bottom: 12px;

    h3 {
      font-size: 18px;
    }
  }
}