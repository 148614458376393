@import '~@admobilize/ui-kit/variables';

.multiselector {
  padding-bottom: 6px;

  .search-filter-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    transition: height ease-out 0.3s;

    button {
      margin-left: 0;
    }

    > .search-box {
      width: 100%;
    }

    .filter-menu {
      .dropdown-menu {
        padding: 0;
        margin: 0;
      }
    }
  }

  .toolbar {
    margin-bottom: 12px;
    height: auto;
    max-height: 56px;
    padding: 0px 9px;
    .checkbox-wrapper {
      margin: 0px;
    }
  }

  .item-list {
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    gap: 4px;
    border: 1px solid $brand-grey;
    max-height: 100vh;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding: 8px;
      width: 100%;
      color: $text-dark;
      cursor: pointer;

      .checkbox-wrapper {
        height: 30px;
      }

      .show-status {
        height: 20px;
        width: 20px;
      }
    }

    .item:hover {
      background-color: #f0f2f7;
      .action-buttons {
        display: flex;
      }
      .show-status {
        display: none;
      }
    }
  }

  .action-buttons {
    display: none;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 0;

    button {
      width: 30px;
      height: 30px;
      margin: 0px 2px;
    }

    &.is-active {
      display: flex;
    }
  }
}
