@import '~@admobilize/ui-kit/variables';

.info-section {
  position: relative;
  padding-top: 24px;

  .form-group {
    margin-bottom: 12px !important;

    label {
      font-size: 18px;
      margin-bottom: 0px;
    }
  }
}

.device-section,
.user-section {
  padding-top: 24px;

  .search-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .search-box {
      width: 100%;

      input {
        width: 100%;
      }
    }

    .horizontal-separator {
      padding-left: 16px;
      margin: 5px 16px 5px 0px;
      border-right: 1px solid #dae0f2;
    }

    .btn-add-user {
      white-space: nowrap;
    }
  }

  .add-invite-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }

  .search-box {
    box-shadow: none;
    border: 1px solid $text-dark;
    padding-right: 12px;
  }

  .toolbar {
    justify-content: space-between;
    align-items: center;
    padding: 18px 0;
    border-bottom: 1px solid #ebedf2;
    margin-bottom: 12px;
    height: auto;
  }

  .device-list {
    .checkbox-wrapper {
      margin-left: 0px;
    }
  }

  .device-row {
    margin-bottom: 8px;
  }

  .user-list {
    .toolbar {
      padding: 12px 0px;

      .checkbox-wrapper {
        padding: 12px;
        margin-left: 0px;
      }
    }

    .bulk-action-container {
      display: flex;
    }
  }

  .user-row {
    position: relative;

    .checkbox-wrapper {
      padding: 12px;
      margin-left: 0px;
    }

    .user-data-row {
      display: flex;
      flex-direction: column;

      .user-name {
        font-weight: normal;
        font-size: 12px;
        color: $brand-slate-blue-medium;
      }

      .user-role {
        color: #b1b6c7;
        font-size: 10px;
        font-weight: normal;
        margin-left: 8px;
      }

      .user-email {
        font-weight: normal;
        margin-top: -8px;
      }
    }

    .quick-action-buttons {
      position: absolute;
      top: 0px;
      right: 12px;
      height: 100%;
      align-items: center;
      display: none;
    }

    &:hover {
      .quick-action-buttons {
        display: flex;
      }
    }
  }

  .no-users {
    display: flex;
    flex-direction: column;

    .button-group {
      display: flex;
      justify-content: space-between;
    }

    p {
      margin-bottom: 12px;
    }
  }

  .icon-button {
    margin-left: 8px;
  }
}

.project-preferences-form {
  position: relative;
  padding-top: 24px;

  .row {
    margin: 0;
  }

  .form-group {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 12px !important;

    &:not(:last-child) {
      padding-right: 24px;
    }
  }

  .threshold-range {
    .custom-range {
      margin-right: 8px;
    }

    span {
      color: $text-dark;
    }
  }

  .section-title {
    padding-bottom: 4px;
    margin-bottom: 24px;
    border-bottom: 1px solid #cfd5e6;

    h4 {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 12px;
      color: #2f64f5;
    }
  }
  .section-container {
    &:last-child {
      .section-title {
        border-bottom: none;
      }
    }
  }
}

.transfer-devices {
  h3 {
    font-size: 22px;
    font-weight: bold;
    color: $text-dark;
    margin-bottom: 4px;
  }

  h4 {
    font-size: 16px;
    color: $brand-slate-blue-medium;
    margin-bottom: 24px;
  }

  .button-group {
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
    border-top: 1px solid #cfd5e6;

    button {
      margin-left: 24px;
    }
  }
}

.new-user-box {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #cfd5e6;

  h2 {
    font-size: 22px;
    margin: 0;
    color: $brand-slate-blue;
    font-weight: bold;
  }

  .new-user-btn-container {
    display: flex;
    align-items: center;
    margin-top: 24px;

    p {
      margin: 0 0 0 24px;
      color: $brand-slate-blue-medium;
      font-size: 16px;
    }
  }

  .new-user-form-container {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .row {
      margin: 0;
    }

    .form-group {
      padding-left: 0;
      padding-right: 0;

      &:not(:last-child) {
        padding-right: 24px;
      }
    }

    .button-container {
      margin-top: 24px;
      padding: 0;
      display: flex;
      justify-content: flex-end;

      button:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}

.modal {
  .modal-transfer-devices {
    .modal-content {
      .modal-body {
        padding: 48px;
      }
    }
  }

  .modal-add-user {
    max-width: 650px;

    .modal-content {
      .modal-header {
        border-bottom: none !important;
        padding: 46px 48px 24px !important;
        display: flex;
        flex-direction: column;

        h2 {
          font-size: 22px;
          margin: 0 0 4px 0;
          color: $brand-slate-blue;
          font-weight: bold;
        }

        h4 {
          font-size: 16px;
          margin: 0;
          color: $brand-slate-blue-medium;
        }
      }

      .modal-body {
        padding: 0 48px 48px !important;

        label {
          font-size: 16px;
          font-weight: normal;
        }
      }
      .modal-footer {
        display: flex;
        justify-content: space-between;

        .button-container {
          display: flex;
          justify-content: flex-end;
          width: 100%;

          button {
            &:first-child {
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
}
