.side-panel {
  position: relative;
  height: 100%;
  margin-right: 24px;

  .side-panel-container {
    width: 20px;
    height: 100%;
    background: white;
    border-radius: 0 5px 5px 0px;
    transition: width ease-out 0.3s;
  }

  .side-panel-content {
    visibility: hidden;
    opacity: 0;
  }

  .side-panel-expand-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: -16px;
    width: 32px;
    height: 32px;
    background: white;
    border-radius: 50%;
    box-shadow: 0px 3px 5px 0px #0a1b650d;
    cursor: pointer;

    img {
      width: 12px;
      height: 12px;
    }

    &:hover {
      background: #ebf1ff;

      img {
        filter: saturate(2.7) brightness(1.32) hue-rotate(354deg);
      }
    }
  }

  &.expanded {
    .side-panel-container {
      width: 392px;

      .side-panel-content {
        display: flex;
        padding: 12px;
        visibility: visible;
        opacity: 1;
        transition: visibility, opacity 0.5s;

        > :first-child {
          width: 100%;
        }
      }
    }

    .side-panel-expand-button {
      transform: rotate(180deg);
    }
  }
}
