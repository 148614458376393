.checkbox-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  border: 1px solid #D1D1D1;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

  .image-container {
    padding: 12px;
  }

  .title-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 4px;
    border-top: 1px solid #D1D1D1;

    p {
      width: 100%;
      text-align: center;
      font-size: 14px;
      line-height: 14.4px;
    }
  }

  &.selected {
    border-color: #2F64F5;

    .title-container {
      border-color: #2F64F5;
      background-color: #2F64F5;

      p {
        color: white;
      }
    }

    &.disabled {
      border-color: #CCC;
    }
  }

  &:hover {
    border-color: #2F64F5;

    .title-container {
      background-color: #D9E4FF;
      border-color: #2F64F5;
      border-radius: 0 0 5px 5px;

      p {
        color: #455079;
      }
    }
  }

}