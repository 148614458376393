/*
91px: App header height
92px: Toolbar height
200px: Widgets height
72px: table header height
62px: footer height
*/

$table-offset: 517px;

.licenses-container {
  display: flex;
  flex-direction: column;
  padding: 0 32px;

  .toolbar {
    .title {
      img {
        margin-right: 12px;
      }
    }

    .search-box {
      margin: 0 12px;

      input {
        width: 250px;
      }
    }
  }

  .multiple-filter {
    .search-box {
      width: auto;
    }
  }

  .widgets-row {
    display: flex;
    padding: 0;
    height: 200px;

    .custom-card {
      flex: 1 1;
      cursor: default;

      &:hover {
        background: white;
        box-shadow: 0px 3px 5px #07165826;
      }

      &:not(:last-child) {
        margin-right: 24px;
      }

      .card-header {
        border-bottom: 1px solid #d3dded;
        padding-bottom: 12px;
      }

      .card-body {
        margin-top: 12px;
        margin-bottom: 0;
      }
    }
  }

  .display-digit.value-container .value {
    font-size: 50px;
  }

  .display-stats-list {
    li {
      margin-bottom: 8px;

      div {
        height: 20px;
      }

      img {
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }
    }
  }

  .react-table {
    overflow: auto;
    padding: 0;

    &.has-action-buttons {
      table {
        tbody {
          tr {
            td {
              &.action-buttons {
                width: 114px;
              }
              .action-buttons {
                .dropdown {
                  display: flex;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }

    table {
      height: calc(100vh - 458px);
      border-radius: 10px;

      thead tr th,
      tbody tr td {
        &:nth-child(1) {
          //License ID
          width: 162px;
          padding-left: 24px;
        }
        &:nth-child(2) {
          //Project
          width: auto;
        }
        &:nth-child(3) {
          //Device Name
          width: auto;
        }
        &:nth-child(4) {
          //Status
          width: 150px;
        }
        &:nth-child(5) {
          //Solution
          width: 110px;
        }
        &:nth-child(6) {
          //Admin
          width: auto;
        }
        &:nth-child(7) {
          //Valid From
          width: 150px;
        }
        &:nth-child(8) {
          //Valid Until
          width: 150px;
        }
        &:nth-child(9) {
          //Demo
          width: 120px;
        }

        .icon {
          width: 30px;
          height: 30px;
        }
        .flag {
          display: flex;
          justify-content: center;
        }
      }

      tbody {
        height: calc(100vh - #{$table-offset});

        tr td {
          &:nth-child(9) {
            //Demo
            width: 114px;
          }

          .valid-until {
            &.expired {
              color: #ef5658;
            }
          }

          .ellipsis {
            flex-wrap: wrap;
            overflow: hidden;

            div {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

.create-license-modal,
.reassign-license-modal {
  &.modal-dialog {
    max-width: 650px;
  }

  .solution-dropdown {
    .dropdown-list-container {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }

  .button-container {
    justify-content: flex-end;
    display: flex;

    button {
      margin-left: 24px;
    }
  }
}

.reassign-license-modal {
  .react-select-virtualized {
    & > div {
      &:nth-child(4) {
        width: 100%;
      }
    }
  }
}
