@import '~@admobilize/ui-kit/variables';

.dropdown-search {
  .react-selector__menu {
    .search-box {
      margin: 12px;
    }

    .dropdown-search-list {
      background-color: white;

      &.empty {
        display: none;
      }

      .dropdown-search-list-item {
        color: $brand-slate-blue;
        padding: 0 24px;
        line-height: 48px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;

        &:hover {
          color: $brand-blue-medium;
          background-color: $status-secondary;
        }
      }
    }

    .empty-message {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $brand-grey;
      padding: 12px;
    }
  }
}