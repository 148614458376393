.modal-image-preview {
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-height: 45vh;

    .heatmap-button {
      margin-top: 12px;
      width: 100%;

      div {
        justify-content: center;
      }
    }
  }
}