// CSV Template

.template {
  margin-bottom: 24px;

  .first-column {
    height: 24px;
    width: 24px;
    background: #fafafa;
    border-right: 1px solid #b6b6b6;
    text-align: center;
  }

  .template-header {
    background: #437853;
    border-radius: 5px 5px 0 0;
    border-top: 1px solid #1b3a24;
    color: white;
    text-align: center;
    padding: 4px;
    position: relative;

    .fake-button {
      border: 1px solid #1b3a24;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 8px;
      border-radius: 25px;

      &.close-btn {
        background-color: #eb6b5f;
        left: 8px;
      }

      &.resize-btn {
        background-color: #f4bf4f;
        left: 28px;
      }

      &.minimize-btn {
        background-color: #65c554;
        left: 48px;
      }
    }
  }

  .template-first-row {
    display: flex;
    background: #fafafa;
    border: 1px solid #b6b6b6;

    .corner-square {
      background: white;
      color: white;
    }

    .template-letters {
      display: flex;
    }
  }

  .template-columns, .template-values {
    display: flex;
    border: 1px solid #b6b6b6;
    border-top: none;
  }

  .template-column-names {
    display: flex;
  }

  .column {
    width: 120px;
    text-align: center;
    border-right: 1px solid #b6b6b6;
  }
}