#timezone-info {
  &.btn.focus, &.btn:focus {
    box-shadow: none;
  }

  &:hover {
    background-color: transparent;
    cursor: not-allowed;

    img {
      cursor: not-allowed;
    }
  }

  img {
    filter: invert(43%) sepia(17%) saturate(240%) hue-rotate(167deg) brightness(100%) contrast(86%);
  }
}