@import '~@admobilize/ui-kit/variables';
.credentials {
  min-height: 100px;
  max-height: calc(100% - 82px);
  overflow-y: auto;
  .credential {
    margin: 12px 0px;
    .credential-header {
      .accordion-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .accordion-title {
          display: flex;
          align-items: center;
          flex: auto;

          background-color: $background;
          padding: 12px;
          border-radius: 5px;
          margin-right: 12px;

          .credential-name {
            flex: 4;
            margin-left: 8px;
            color: $brand-slate-blue;
          }

          cursor: pointer;
        }

        .action-buttons {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 6px;

          .icon-button {
            &.outline {
              background-color: transparent;
              border: none;

              img {
                filter: none;
              }
            }
          }
        }
      }
    }

    .credential-body {
      margin-top: 6px;
      padding: 12px;
    }
  }
}
