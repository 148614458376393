.modal {
  .custom-widget-modal {
    max-width: 900px;

    .checkbox-wrapper {
      margin: 24px 8px 0;

      .custom-checkbox-label {
        font-weight: normal;
        user-select: none;
      }
    }

    .widget-options-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 12px;
      row-gap: 12px;
      height: 500px;
      overflow-y: auto;
      margin-top: 24px;
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
      padding-top: 24px;
      margin-top: 24px;
      border-top: 1px solid #D1D1D1;

      button {
        margin-left: 24px;
      }
    }
  }
}