@import '~@admobilize/ui-kit/variables';

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 24px;
  text-align: center;

  img {
    width: 80px;
    height: 80px;
  }

  .text-container {
    max-width: 400px;

    h2 {
      margin-bottom: 0px !important;
    }

    p {
      text-align: center;
      color: $brand-slate-blue-medium;
    }
  }
}