.reports-container {
  .no-data-message {
    height: calc(100vh - 152px);
  }

  .accordion {
    .accordion-item {
      .item-body {
        padding: 0 15px;
      }
    }
  }

  .report-info {
    position: relative;
    padding-top: 24px;

    .form-group {
      div {
        &.btn-group {
          display: flex;
          .btn {
            color: #455079;
            box-shadow: none;
          }
        }
      }

      .timezone-selector {
        width: 100%;

        .react-select__dropdown {
          button {
            &.active {
              div, i {
                color: white;
              }
            }
          }
          .btn.active {
            color: white;
          }
        }
      }
    }

    #name-input, #email-input {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .dropdown, .dropdown button {
      width: 100%;
    }
  }
}