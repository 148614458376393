@import "~@admobilize/ui-kit/variables";

.tag {
  margin: 4px 0px 4px 0px;

  &.disabled {
    &:hover {
      cursor: not-allowed;
      background-color: #E2EDFF;
    }
  }
}