@import '~@admobilize/ui-kit/variables';
/*
  24px: side panel padding
  36px: accordion section height
  12px: accordion section margim bottom
  6px: multiselector padding
  43px: searchbox height
  24px: toolbar height
  24px: toolbar margin
  31px: button height
  12px: item body padding
  92px: container header height
  91px: container toolbar height
*/

$components-offset: 24 + 6 + 43 + 24 + 24 + 31 + 12 + 92 + 91;
$toolbar-offset: 24 + 12;
$accordion-section-height: 36;
$cms-sections: 3;
$accordion-section-margin: 12;
$cms-offset: ($accordion-section-height * $cms-sections) + ($accordion-section-margin * ($cms-sections - 1));
$default-offset: $accordion-section-height;

.side-panel {
  .accordion {
    .accordion-item {
      &.is-expanded {
        .item-body {
          padding-bottom: 0px;
        }
      }
      .item-header {
        padding: 6px 12px;
      }
    }
  }
  .side-menu {
    display: none;
    .item-body {
      .btn-primary {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
  }

  &.expanded {
    .side-menu {
      display: block;

      .item-list {
        height: calc(100vh - #{$default-offset}px - #{$components-offset}px);

        &.no-devices {
          height: calc(100vh - #{$default-offset}px - #{$components-offset}px + #{$toolbar-offset}px);
        }
      }

      &.cms-mode {
        .item-list {
          height: calc(100vh - #{$cms-offset}px - #{$components-offset}px);

          &.no-devices {
            height: calc(100vh - #{$cms-offset}px - #{$components-offset}px + #{$toolbar-offset}px);
          }
        }
      }
    }
  }
}
