@import '~@admobilize/ui-kit/variables';

.custom-dropdown {
  .multiselect.dropdown {
    display: flex;

    &> button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0px;
      color: #A1A7B3;
      background-color: white;
    }

    .dropdown-menu {

      .dropdown-item {
        font-size: 16px;
        display: flex;
        margin-bottom: 0px !important;
        padding-top: 6px;
        padding-bottom: 6px;
      }

      .dropdown-actions {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 0px;
    
        .clear-button {
          justify-content: center;
          font-weight: bold;
          font-size: 16px;
          display: flex;
          flex: 1;
          align-items: center;
          padding-top: 12px;
          padding-bottom: 12px;
    
          &.btn:focus {
            box-shadow: none;
          }
    
          &:hover {
            background-color: $status-danger-light;
          }
        }
      }
    }
  }
}