@import '~@admobilize/ui-kit/variables';

/*
 * 92px: header height
 * 91px: filter-bar height
 * 51px: drawer header
 * 24px: drawer padding
 * 24px: item-body padding
 * -12px: last item margin-bottom
 * TOTAL = 270px -> accordion offset
 */
$accordion-offset: 270px;
$accordion-number-of-items: 4;

#projects {
  .projects-container {
    display: flex;

    .loading-container {
      width: 100%;
      height: calc(100vh - 183px);
      display: flex;
      justify-content: center;
      align-content: center;
    }

    .projects-grid {
      padding: 0 12px 24px;

      .project-id {
        width: 49%;
      }

      .project-token {
        width: 49%;
      }

      .custom-card {
        margin-right: 12px;
        margin-left: 12px;

        .card-header {
          h3,
          h4 {
            max-width: 300px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .value-block {
            height: 30px;
          }
        }

        .card-footer {
          img {
            height: 20px;
            // The original icon should be #455079 so after applying the filter
            // it will become #326df5 the closest we got from #2F64F5
            filter: saturate(2.7) brightness(1.32) hue-rotate(354deg);
          }
        }
      }
    }

    .details-drawer {
      margin-right: 24px;
      min-width: 392px;
      width: 392px;
      padding: 12px 12px 0;
      margin-bottom: 12px;

      .details-drawer-header {
        padding: 12px;
        margin-bottom: 12px;

        h3 {
          font-size: 18px;
        }
      }

      .accordion {
        /*
         * 100vh: screen viewport height
         * 187px: accordion offset
         */
        height: calc(100vh - #{$accordion-offset});

        .accordion-item {
          &.is-expanded {
            .item-body {
              /*
               * 100vh: screen viewport height
               * 12px: accordion-item with bottom margin
               * 48px: item-header height (with top & bottom padding and bottom margin)
               * $accordion-number-of-items: number of accordion-item items
               */
              height: calc(100vh - #{$accordion-offset} - ((12px + 48px) * #{$accordion-number-of-items}));
              padding: 0px 15px;

              .filter-list {
                color: $text-dark;
              }

              .edit-button-container {
                padding: 12px 0px;
              }
            }
          }
        }
      }
    }
  }
}
