#header {
  .header-tools {
    .period-selector .DateRangePicker .DateRangePickerInput {
      background-color: #ffffff;
      height: 40px;
    }
    .filters .filter-option > div {
      margin-right: 15px;
      width: 400px;

      @media (max-width: 992px) {
        width: auto;
      }
    }
  }
}
