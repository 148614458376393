@import '~@admobilize/ui-kit/variables';

#login-screen {
  //noinspection CssReplaceWithShorthandSafely
  .left-area {
    background: url('../../assets/images/icosahedron.svg') no-repeat top 34px right -80px,
    url('../../assets/images/icosahedron.svg') no-repeat bottom 64px left 64px;
    background-size: 150px 141px, 225px 213px;
    background-color: $brand-grey-light;
    color: $brand-slate-blue;

    .logo {
      width: 300px;
      max-width: 70%;
      padding-top: 5vh;

      img {
        width: 100%;
        height: auto;
      }
    }

    .form-box {
      background-color: white;
      padding: 30px 50px;
      box-shadow: 0 3px 6px #00000029;
      border-radius: 5px;
      min-width: 70%;
    }

    .btn-primary {
      background-color: $brand-grey-light;
      border-color: $brand-grey-light;
    }

    a {
      color: $brand-slate-blue;
      font-size: 18px;


      span {
        text-decoration: underline;
        font-size: 18px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  //noinspection CssReplaceWithShorthandSafely
  .right-area {
    background: url('../../assets/images/icosahedron_low_opacity.svg') no-repeat top 4vw right 333px,
      url('../../assets/images/icosahedron_low_opacity.svg') no-repeat bottom -12px right 82px;
    background-size: 150px 142px, 15vw 257px;
    background-color: $brand-grey-light;

    .right-container {
      padding: 8vw;

      .caption {
        font-size: 24px;
      }
    }
  }

  @media (max-width: 992px) {
    .left-area {
      .form-box {
        width: 90%;
      }
    }
    .right-area {
      .right-container {
        h1 {
          font-size: 4rem;
        }
      }
    }
  }
}
