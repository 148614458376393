@import '~@admobilize/ui-kit/variables';
.period-selector {
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  box-shadow: 0px 3px 9px #1732a90d;
  padding: 10px;
  width: 260px;
  height: 43px;

  &:hover {
    background: #f5f7fa;
  }

  &.calendar-open {
    background-color: #e2edff;
  }

  &.outline {
    border: 1px solid $text-dark;
  }

  .react-datepicker-popper {
    z-index: 30;
    top: 16px !important;
    left: -10px !important;
  }

  .react-datepicker {
    display: flex;
    padding: 24px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 3px 9px 0px rgba(23, 50, 169, 0.05);
    min-height: 342px;
  }

  .react-datepicker__aria-live {
    display: none;
  }

  .custom-calendar-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    .nav-arrow {
      background-color: white;
      color: $brand-blue-medium;
      border-radius: 5px;
      width: 30px;
      height: 30px;

      &:hover {
        background-color: #e2edff;
      }

      &:active {
        background-color: #e2edff;
        box-shadow: 0px 3px 6px #07165826;
        border: 1px solid $brand-blue-medium;
      }

      i {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;
        border: 1px solid transparent;
        border-radius: 3px;

        &::before {
          font-size: 25px;
        }
      }
    }
  }

  .react-datepicker__month {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }

  .react-datepicker__current-month {
    color: $brand-slate-blue;
    font-weight: bolder;
  }

  .react-datepicker__week {
    display: flex;
  }

  .react-datepicker__day--today {
    text-decoration: underline;
    font-weight: bolder;
  }

  .react-datepicker__day-names {
    display: flex;
    color: #d1d1d1;

    .react-datepicker__day-name {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 46px;
    }
  }

  .react-datepicker__day {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2f64f5;
    width: 46px;
    height: 38px;

    &:hover {
      background-color: #e2edff;
    }
  }

  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    background-color: #e2edff;
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end,
  .react-datepicker__day--selecting-range-start {
    border: 1px solid #2f64f5;
    background-color: #e2edff;
  }

  .react-datepicker__day--outside-month {
    color: transparent;
    background-color: transparent;
    border: transparent;
    pointer-events: none;
    &:hover {
      background-color: transparent;
    }
  }

  .info-panel {
    padding: 0;

    .presets-list {
      display: flex;
      flex-direction: column;
      margin-bottom: 4px;

      .PresetDateRangePicker_button {
        // Reset button default browser style
        font-size: 100%;
        font-family: inherit;
        background-color: transparent;

        // Button preferences
        text-align: left;
        font-weight: normal;
        color: $brand-slate-blue;

        display: block;
        padding: 8px 24px;
        margin-right: 0;
        margin-bottom: 4px;
        border-radius: 5px;
        border: 1px solid transparent;
        width: 150px;

        &:hover {
          background-color: #e2edff;
          color: $brand-blue-medium;
        }

        &.PresetDateRangePicker_button__selected {
          background-color: #e2edff;
          color: $brand-blue-medium;
          border: 1px solid $brand-blue-medium;
          outline: none;
        }
      }
    }

    .calendar-footer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      width: 100%;
      height: 120px;
      bottom: -100px;
      left: 0;

      position: absolute;
      background: white;

      border-radius: 5px;
      box-shadow: 0px 5px 6px #00000029;

      padding: 12px 20px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 1px solid #cfd5e6;

      .calendar-filters {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .checkbox-wrapper {
          &.selected {
            .custom-checkbox-label {
              color: #455079;
            }
          }
          .custom-checkbox-label {
            color: #cfd5e6;
          }
        }

        .time-filter {
          display: flex;

          .time-range-filter {
            display: flex;
            align-items: center;

            &.form-group {
              margin-bottom: 0;

              > div {
                width: 70px;
              }

              span {
                color: #cfd5e6;

                &.active {
                  color: #455079;
                }
              }

              .custom-input {
                cursor: not-allowed;

                .form-control {
                  color: #cfd5e6;
                }
              }

              .form-control {
                border: 1px solid transparent;
                transition: none;
                padding-top: 1px;
                padding-bottom: 1px;
                height: auto;
              }
            }

            span {
              margin: 0 8px;
            }
          }
        }

        .day-of-week-filter {
          display: flex;

          .selector {
            display: flex;
            align-items: center;
            justify-content: space-between;

            list-style: none;
            margin: 0;
            padding: 0;

            li {
              display: flex;
              align-items: center;
              justify-content: center;

              font-weight: 700;
              font-size: 16px;
              line-height: 19px;

              color: #2f64f5;
              background-color: white;
              border: 1px solid transparent;
              border-radius: 5px;
              margin: 0 4px;

              padding: 12px;
              gap: 10px;

              width: 58px;
              height: 42px;
              cursor: pointer;

              &:hover {
                background-color: #e2edff;
              }

              &.selected {
                background-color: #e2edff;
                border: 1px solid #2f64f5;
                box-shadow: 0px 3px 6px #07165826;
              }

              // Override with disabled style
              &.disabled {
                opacity: 0.3;
                cursor: not-allowed;

                &:hover {
                  background-color: transparent;
                }

                &.selected {
                  background-color: #e2edff;
                }
              }

              img {
                margin-right: 8px;
              }
            }
          }
        }
      }

      .panel-controls {
        display: flex;
        justify-content: space-between;
        padding-top: 12px;

        button {
          width: 48%;
          font-weight: bold;
        }

        .btn-block + .btn-block {
          margin-top: 0;
          border-radius: 5px;
        }

        .btn-link {
          border: 2px solid #2f64f5;
          border-radius: 5px;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .react-datepicker {
      flex-direction: column;
      padding: 0px;

      .react-datepicker__month-container {
        min-height: 310px;
        padding: 24px;
      }

      .info-panel {
        .presets-list {
          border-top: 1px solid #cfd5e6;
          flex-direction: row;
          align-items: center;
          width: 382px;
          padding: 12px 10px;

          .PresetDateRangePicker_button {
            padding: 6px;
            border-radius: 3px;
            width: auto;
            text-align: center;
            margin: 0px;
          }
        }

        .calendar-footer {
          height: 175px;
          bottom: -175px;

          .calendar-filters {
            flex-direction: column;
            justify-content: flex-start;

            .time-filter {
              justify-content: space-between;
            }

            .day-of-week-filter {
              margin-top: 12px;
              .custom-checkbox-label {
                margin-right: 4px;
              }
              .selector {
                justify-content: center;

                li {
                  width: 32px;
                  height: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
