/*
  - menu: 76px
  - container: 64px
  - sidepanel: 320px
  - tabs: 96px
*/
$offset: 580px;

.edit-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 24px;
  padding: 12px;
  z-index: 10;
  background: #EBF1FF;
  width: calc(100% - #{$offset});

  &.small {
    position: absolute;
    width: auto;
    right: 0px;
    top: 0px;
    bottom: auto;
    background: none;
    padding-top: 8px;
  }
}

.button-hint {
  color: #455079;
}