@import '~@admobilize/ui-kit/variables';

.custom-card {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 3px 5px #07165826;
  margin-bottom: 24px;
  cursor: pointer;

  &:hover {
    background-color: #F5F7FA;
    box-shadow: 0px 9px 15px #07165826;
  }

  &.selected {
    background: white;
    border: 2px solid #2F64F5;
    box-shadow: 0px 9px 15px #07165826;
    padding: 22px;
  }

  h3 {
    font-size: 18px;
    font-weight: bold;
    color: $text-dark;
  }

  h4 {
    font-size: 16px;
    color: $brand-slate-blue-medium;
  }

  h5 {
    font-size: 16px;
    margin: 0;
    font-weight: bold;
    color: $brand-blue-medium;
  }

  .card-header {
    border-bottom: none;
    padding: 0;
    background: none;
  }

  .card-body {
    padding: 0;
    min-height: auto;
    margin-top: 24px;
    margin-bottom: 12px;
  }

  .card-footer {
    background: none;
    padding: 12px 0 0;
    border-top-color: #DAE0F2;

    img {
      margin-right: 8px;
    }
  }

  .value-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}