.model-box {

  .header-wrapper {
    display: flex;
    align-items: center;

    button {
      margin-left: 16px;
      outline: none;
      box-shadow: none;
    }
  }

  .item-header {
    width: 100%;
  }

  .accordion {
    .accordion-item {
      &.is-expanded {
        .item-body {
          padding: 12px 0px;
          height: 275px;
          width: calc(100% - 46px);

          .container {
            height: auto;

            .row:last-child {
              .form-group {
                margin-bottom: 0px;
            }
            }
          }
        }
      }
    }
  }

  .add-model-btn {
    margin-top: 12px;
    width: 100%;

    div {
      justify-content: center;
    }
  }

}