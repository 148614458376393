@import '~@admobilize/ui-kit/variables';
/*
 * 60px: sidebar-navigation width
 * 24px: comparison container padding
 * TOTAL = 100vw - 84px -> table width
 */

$table-width: calc(100vw - 84px);

/*
  * 92px: app-header height (with top & bottom padding)
  * 91px: filter-bar height (with top & bottom padding)
  * 12px: reports-container with bottom padding
  * 72px: thead hight
  * 46px: pagination-wrapper height with top padding
  */
$table-offset: 313px;

/*
 * 92px: Header height
 * 91px: filter bar height
 * 63px: side-menu header height (with top & bottom padding and bottom margin)
 * 24px: side-menu top & bottom padding
 * 12px: data-container bottom padding
 */
$header-offset: 91px + 92px;
$accordion-offset: $header-offset + 99px;
$accordion-number-of-items: 4;
$sections-margin: ($accordion-number-of-items - 1) * 12px;
$sections-header: $accordion-number-of-items * 48px;

.reports-container {
  display: flex;
  flex-direction: column;
  padding: 0 12px 12px 12px;

  .filter-bar {
    padding: 12px 0;
    display: flex;
    justify-content: space-between;

    .title {
      margin-right: 24px;
      padding-left: 24px;

      img {
        margin-right: 12px;
      }
    }
  }

  .reports-content {
    display: flex;

    .react-table {
      &.slim-table {
        table {
          thead tr th,
          tbody tr td {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              width: auto;
            }

            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
              display: none;
              width: 0px;
            }
          }
        }

        + .pagination-wrapper {
          .footer {
            /* Widths
            * 124px: pagination
            * 370px: side menu
            * 12px: side menu margin
            */
            width: calc(100% - 506px);
          }
        }
      }

      table {
        thead tr th,
        tbody tr td {
          &:nth-child(1) {
            //Name
            text-align: left;
            padding-left: 24px;
            width: auto;
          }
          &:nth-child(2), //Email
          &:nth-child(3) {
            //project
            width: auto;
          }

          &:nth-child(4), //Frequency
          &:nth-child(5) {
            //Status
            text-align: center;
            width: 170px;
          }
          &:nth-child(6) {
            //Last sent
            width: 180px;
          }
          &:nth-child(7) {
            //File expiration Date
            width: 240px;
          }
        }

        tbody {
          /*
          * 100vh: screen viewport height
          * 229px: table offset
          */
          height: calc(100vh - #{$table-offset});

          tr {
            td {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;

              &.action-buttons {
                width: 220px;
                justify-content: center;

                .action-buttons-options {
                  display: flex;
                  justify-content: center;
                }
              }

              .ellipsis {
                flex-wrap: wrap;
                overflow: hidden;

                div {
                  display: block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }

    .details-drawer {
      margin-left: 12px;

      .details-drawer-body {
        .accordion {
          .accordion-item {
            &.is-expanded {
              .item-body {
                /*
                * 48px: accordion item header height
                * 36px: sections margin bottom
                * 24px : accordion padding
                * 63px: sidepanel header
                */
                height: calc(100vh - #{$accordion-offset} - #{$sections-header} - #{$sections-margin});
                overflow-y: auto;

                .filter-list {
                  color: $text-dark;
                }
              }
            }
          }
        }
      }
    }
  }
}
