/*
  - header: 92px
  - container: 48px
  - filter: 64px
  - tabs header: 101px
*/
$offset: 305px;

.integrations-container {
  padding: 24px;

  .integration-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 12px;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;

    a {
      margin-right: 12px;
    }
  }

  .filter-bar {
    display: flex;
    align-items: center;
    padding: 12px 0;

    .title {
      margin-right: 24px;
      padding-left: 24px;

      img {
        margin-right: 12px;
      }
    }
  }

  .horizontal-separator {
    border-bottom: 1px solid #dae0f2;
    margin: 24px 0;
  }

  .account-fields {
    padding: 12px;
  }

  .left-button {
    margin-right: 24px;
  }

  // Account Items
  .account-item {
    display: flex;
    margin-top: 12px;

    .account-accordion-item {
      display: flex;
      flex-direction: column;
      flex: 1;
      cursor: pointer;

      &.active {
        .account-title {
          background-color: #e2edff;

          .arrow-icon {
            transform: rotate(90deg);
          }
        }

        .account-fields {
          display: flex;
        }
      }
    }

    .account-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f0f2f7;
      padding: 12px;
      border-radius: 5px;
      height: 46px;

      .user-icon,
      .media-icon,
      .device-icon {
        margin-right: 12px;
      }

      .arrow-icon,
      .user-icon,
      .media-icon,
      .device-icon {
        width: 16px;
        height: 16px;
      }
    }

    .account-fields {
      flex-direction: column;
      display: none;
    }

    .account-buttons {
      display: flex;
      height: 46px;
      align-items: center;

      .account-button {
        margin-left: 12px;
      }

      .spinner-border {
        color: #455079;
      }
    }
  }

  .no-results-found {
    display: flex;
    justify-content: center;
    margin-top: 12px;

    p {
      color: #767e9a;
    }
  }

  .no-data-message {
    /*
    * header: 92px
    * toolbar: 64px
    * container padding: 48px
    */
    height: calc(100vh - 92px - 64px - 48px);
  }
}

.import-integration-modal {
  color: #455079;

  &.modal-dialog {
    max-width: 650px;
  }

  .button-container {
    justify-content: flex-end;
    display: flex;

    button {
      margin-left: 24px;
    }
  }

  .file-input-container {
    display: flex;
    align-items: center;

    #input-file-btn {
      display: none;
    }

    .file-info {
      margin-left: 12px;
      overflow: hidden;
      overflow-wrap: break-word;
    }
  }

  .button-container {
    margin-top: 24px;
  }
}
