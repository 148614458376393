@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~@iconscout/unicons/css/line.css';
@import '~@admobilize/ui-kit/main.scss';

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $background;

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $status-secondary-darker;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $brand-slate-blue;
    border-radius: 100px;
  }
}

.app {
  display: flex;
  height: 100vh;
  width: 100%;

  .app-loading {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    z-index: 9999;
    width: 100%;
    height: 100%;

    background: $background;
  }

  .app-container {
    width: 100%;

    .alert-container {
      position: fixed;
      top: 10px;
      width: 40%;
      left: 30%;
      z-index: 7000;
    }
  }

  @media (max-width: 992px) {
    .app-container .alert-container {
      width: auto;
    }
  }
}

// TODO: Remove after One week of new URL release
.url-change-alert {
  width: 100%;
  background: #3a476f;
  padding: 12px;
  color: white;
  display: flex;
  justify-content: space-between;

  a {
    color: white;
  }

  .close-button {
    cursor: pointer;
  }
}

.ellipsis {
  display: flex;
  width: 100%;

  & > div {
    display: flex;
    width: 100%;

    & > div:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

// TODO: Move to ui-kit
.period-selector {
  .CalendarDay__highlighted_calendar::after {
    content: '';
    width: 6px;
    height: 2px;
    margin-left: calc(50% - 3px);
    background-color: #2f64f5;
    display: block;
  }

  .CalendarDay__highlighted_calendar {
    background-color: white;
  }
}
