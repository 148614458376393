@import '~@admobilize/ui-kit/variables';

.canvas-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 840px;

  @media (max-width: 940px) {
    width: calc(100vw - 100px);
  }

  &.no-preview {
    .konvajs-content {
      background-image: url('../../assets/icons/image.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .canvas-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    h3 {
      font-weight: bold;
      font-size: 20px;
      color: $text-dark;
    }
  }

  .konvajs-content {
    background-color: #e5e5e5;
    margin-bottom: 5px;

    canvas {
      border: 1px solid #d1d1d1 !important;
    }
  }

  .legends-container {
    display: flex;
    height: 35px;

    .legend {
      margin: 0 12px 0 0;
      display: flex;
      align-items: center;
      color: #767E9A;

      &::before {
        display: block;
        margin: 0 5px 0 0;
        width: 12px;
        height: 12px;
        content: "";
      }

      &.zones {
        &::before {
          background-color: #c5776f;
        }
      }

      &.rois {
        &::before {
          background-color: #70af70;
        }
      }

      &.selected {
        &::before {
          background-color: #697ee4;
        }
      }
    }

  }

  .action-buttons {

    .btn {
      margin: 10px 5px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .roi-form-container, .zone-form-container {
    padding: 0 30px;
  }

  .section-header {
    background: #efefef;
    width: 100%;
    padding: 10px 30px;
    border-radius: 5px;
    font-weight: bold;
    color: $text-dark;
    font-size: 16px;
    margin: 10px 15px 20px;
  }
}

.solution-no-zones {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  h4 {
    color: $text-dark;
  }
}