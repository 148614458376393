@import '~@admobilize/ui-kit/variables';

.info-section-component {
  padding: 12px;
  background-color: #F0F2F7;
  border-radius: 5px;
  margin-top: 24px;
  cursor: pointer;

  .info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 14px;
      margin-left: 12px;
    }

    .arrow-icon {
      transition: transform 200ms ease;
      width: 15px;
    }
  }

  .info-body {
    display: none;
    padding: 0 12px;
    margin-top: 12px;

    p {
      font-size: 14px;
      color: $brand-slate-blue-medium;
    }

    b {
      font-size: 14px;
      color: $brand-slate-blue;
    }
  }

  &.open {
    background-color: #E2EDFF;

    .info-header {
      h3 {
        color: $brand-blue-medium;
      }

      .arrow-icon {
        filter: saturate(2.7) brightness(1.32) hue-rotate(354deg);
        transform: rotate(-180deg);
      }
    }
    .info-body {
      display: block;
    }
  }

  &:hover {
    background-color: #D9E4FF;

    .info-header {
      h3 {
        color: $brand-blue-medium;
      }

      .arrow-icon {
        filter: saturate(2.7) brightness(1.32) hue-rotate(354deg);
      }
    }
  }
}