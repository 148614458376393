/*
 * 76px: sidebar-navigation width
 * 24px: comparison container padding
 * TOTAL = 100vw - 100px -> table width
 */
$table-width: calc(100vw - 100px);

/*
 * 92px: app-header height (with top & bottom padding)
 * 91px: filter-bar height (with top & bottom padding)
 * 46px: pagination-wrapper height with top padding
 * 15px: bottom scroll bar
 * TOTAL = 245px -> table offset
 */
$table-offset: 245px;

.comparison-container {
  display: flex;
  flex-direction: column;
  padding: 0 12px;

  .filter-bar {
    padding: 24px 0;
    height: 91px;

    .title {
      margin-right: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 24px;

      img {
        margin-right: 12px;
      }
    }
  }

  .no-data-message {
    height: calc(100vh - 183px);
  }

  .react-table {
    width: $table-width;
    overflow: auto;

    &.facev2 {
      table {
        thead tr th,
        tbody tr td {
          &:nth-child(2) {
            //Impressions
            width: 170px;
          }
          &:nth-child(3) {
            //Views
            width: 120px;
          }
          &:nth-child(5) {
            //Gender
            width: 140px;
          }
        }
      }
    }

    &.scalafacev0 {
      table {
        thead tr th,
        tbody tr td {
          &:nth-child(2) {
            //Site
            width: 175px;
          }
          &:nth-child(3) {
            //Impressions
            width: 160px;
          }
          &:nth-child(4) {
            //Views
            width: 120px;
          }
          &:nth-child(6) {
            //Gender
            width: 130px;
          }
        }
      }
    }

    table {
      /*
      * screen viewport height - table offset
      */
      height: calc(100vh - #{$table-offset});
      border-radius: 10px;

      thead tr th,
      tbody tr td {
        &:nth-child(1) {
          //Device Name
          padding-left: 24px;
        }
        &.empty-column {
          display: none;
        }
      }

      thead {
        tr {
          th {
            .uil {
              margin-right: 8px;
            }

            .header-subtitle {
              font-size: 14px;
              font-weight: normal;
              color: #767e9a;
              display: flex;
            }
          }
        }
      }
    }

    tbody {
      /*Heights
      *92px - App header
      *91px - Toolbar
      *79px  - Navigation
      *46px - footer
      *12px - page padding
      */
      height: calc(100vh - 320px);

      tr {
        color: #767e9a;

        td {
          width: 170px;
          text-align: center;
        }

        .gender-icon,
        .growth-icon {
          margin-right: 8px;
        }

        .direction-icon {
          &:last-of-type {
            margin-right: 8px;
          }
        }

        display-line {
          padding: 0;
        }
      }
    }

    &.has-navigation {
      tbody {
        /*Heights
      *92px - App header
      *91px - Toolbar
      *79px  - Navigation
      *45.5px  - table custom header
      *46px - footer
      *12px - page padding
    */
        height: calc(100vh - 366px);
      }
    }
  }

  .pagination-wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.tooltip {
  .gender-legend {
    margin-right: 5px;
  }
  .growth-legend {
    margin-left: 5px;
  }
}
