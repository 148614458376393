.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttons {
  display: flex;
}

.buttons a {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}