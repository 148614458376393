@import '~@admobilize/ui-kit/variables';

.toolbar {
  display: flex;
  padding: 24px;
  height: 91px;
  justify-content: space-between;

  .title {
    margin-right: 12px;

    > img {
      margin-right: 12px;
      height: 30px;
      width: 30px;
    }
  }

  h2 {
    font-size: 22px;
    margin: 0;
    color: $text-dark;
    font-weight: bold;
  }

  .count {
    padding-left: 12px;
    margin-right: 24px;
    display: flex;
    align-items: center;
    border-left: 1px solid #dae0f2;
    color: $brand-slate-blue-medium;
    font-size: 18px;
  }

  .search-box {
    width: 370px;
    padding-right: 12px;
  }

  .filter-menu-container {
    .search-box {
      width: auto;
    }
  }

  .btn-primary {
    font-weight: bold;
  }

  .checkbox-wrapper {
    margin-left: 12px;

    .custom-checkbox-label {
      font-weight: bold;
    }

    .custom-checkbox {
      background: transparent;

      &.selected {
        background: $brand-blue-medium;
      }
    }
  }

  @media (max-width: 1200px) {
    .search-box {
      width: 300px;
    }
  }

  @media (max-width: 900px) {
    .search-box {
      width: 250px;
    }
  }
}
