@import '~@admobilize/ui-kit/variables';

$create-comment-height: 128 + 24;

.comments-tab-wrapper {
  .create-comment {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;

    .create-comment-message {
      margin-bottom: 12px;

      textarea {
        font-family: 'Lato', sans-serif;
        resize: none;
      }
    }

    .create-comment-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .create-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn {
          height: 30px;
          width: 60px;
          margin-left: 8px;

          .align-items-center {
            justify-content: center;
          }
        }
      }
    }
  }

  .comments {
    overflow-y: auto;
    height: calc(100% - #{$create-comment-height}px);

    .comment-row {
      width: calc(100% - 6px);
      display: flex;
      margin-bottom: 12px;

      .comment {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: $background;
        border: solid 1px $brand-grey;
        border-radius: 10px;
        height: 180px;
        width: 100%;
        padding: 12px;

        .comment-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;

          .comment-date {
            margin-right: 12px;
            font-weight: 600;
            color: $text-dark;
          }

          .comment-sender {
            width: 150px;
            text-align: right;
            font-weight: 600;
            color: $brand-blue-medium;
          }
        }

        .comment-message {
          inline-size: 100%;
          word-break: break-all;
          padding: 0px 12px;
          margin-bottom: 12px;
          overflow-y: auto;
          color: $text-dark;
        }

        .comment-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .comment-status {
            font-size: 12px;
            color: $brand-slate-blue-medium;
          }

          .comment-buttons {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .btn {
              height: 30px;
              width: 30px;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}
