@import '~@admobilize/ui-kit/variables';
$scroll-offset: 6px;

.multiple-filter {
  width: 100%;

  .multiple-filter-header {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    width: 100%;
    background: $status-secondary;
    border-radius: 5px;
    margin: 0 auto;
    margin-bottom: 4px;
    height: 51px;

    .icon-title {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      img {
        filter: saturate(2.7) brightness(1.32) hue-rotate(354deg);
        height: 19px;
        width: 19px;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: $brand-blue-medium;
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 35px;
      justify-self: flex-end;
      margin-left: auto;

      &.no-filters {
        display: none;
      }
    }
  }

  .multiple-filter-tags {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    padding: 4px 12px;
    height: 80px;
    overflow: auto;
    width: calc(100% - #{$scroll-offset});
    margin-bottom: 12px;
    gap: 4px;

    .tag {
      margin: 0px;
      width: fit-content;
      border: 1px solid $status-primary-light;
      height: 24px;
      display: flex;
      justify-content: space-between;
    }

    .no-filters-message {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 14px;

      span {
        color: $brand-slate-blue-medium;
      }
    }

    &.no-filters {
      align-content: center;
    }
  }

  .horizontal-separator {
    border-bottom: 1px solid $brand-blue-light;
    margin: 12px 0;
  }

  .multiple-filter-filters {
    width: calc(100% - #{$scroll-offset});
    height: calc(100vh - 195px - 191px);
    overflow: auto;

    .filter-section-header {
      background-color: $background;
      border-radius: 5px;
      padding: 6px 12px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: $brand-slate-blue;
      width: calc(100% - #{$scroll-offset});
    }

    .filter-section-body {
      width: 100%;
      padding: 12px;

      //Styles for list selector
      .multiselector {
        .item-list {
          border: none;
          padding: 0px;
          .device-virtual-list {
            .item {
              display: flex;
              align-items: center;
              height: auto !important;

              &.selected {
                background-color: $status-secondary;
                color: $brand-blue-medium;
              }
            }
          }
        }
      }

      //Styles for dropdown
      .custom-dropdown {
        width: 100%;
        .dropdown {
          width: 100%;
          button {
            width: 100%;
          }
        }
      }
    }

    .section-separator {
      border-bottom: 1px solid $brand-blue-light;
      margin: 12px 0;
      width: calc(100% - #{$scroll-offset});
    }

    .section-wrapper {
      &:last-child {
        .section-separator {
          display: none;
        }
      }
    }
  }

  &.multiple-filter-slim {
    padding: 0px;
    width: 100%;

    .multiple-filter-filters {
      width: 100%;

      .dropdown-search {
        margin: 0px;
      }
    }
  }
}

.filter-button {
  img {
    filter: saturate(2.7) brightness(1.32) hue-rotate(354deg);
    height: 20px;
    width: 20px;
    margin-right: 5px;
  }

  .filter-button-toggle {
    display: flex;
    justify-content: center;
    align-content: space-between;
    &.has-filter {
      &::after {
        width: 10px;
        height: 10px;
        background-color: $brand-blue-medium;
        border-radius: 50%;
        content: '';
        display: block;
        top: 9px;
        left: 38px;
        position: absolute;
        border: 1px solid $brand-grey-medium;
      }
    }
  }

  &.only-icon {
    .filter-button-toggle {
      margin-left: 12px;
      padding: 10px;
      height: 43px;
      width: 48px;

      img {
        margin: 0px;
      }
    }
  }
}

.dropdown {
  .dropdown-menu.show {
    padding: 12px;
    width: 380px;
  }
}
