#summary-container {
  .no-data-message {
    width: 100%;
    height: calc(100vh - 183px);
  }
  
  .filter-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
#summary-chart-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0 12px 12px 12px ;

  .chart-row {
    &:first-child {
      flex-grow: 0;
    }

    &:last-child {
      flex-grow: 1;
      padding-bottom: 0;
    }

    widget-container {
      .card {
        height: 100%;
      }
    }
  }

  @media (max-width: 992px) {
    display: block;

    .chart-row {
      &:first-child {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        // small screen adjustments
        margin: -5px;

        widget-container {
          flex: none;
          width: 50%;
          margin: 0;

          // small screen adjustments
          padding: 5px;

          &:nth-child(odd):last-child {
            width: 100%;
          }
        }
      }
    }
  }
}