@import '~@admobilize/ui-kit/variables';

.remove-users-modal-wrapper {
  padding: 24px 0px 0px;
  border-top: 1px solid #cfd5e6;
}

.remove-users-modal,
.users-permission-modal {
  height: 200px;
  overflow: auto;

  .user-data-row {
    margin-bottom: 12px;

    .user-name {
      color: $brand-slate-blue-medium;
      font-size: 12px;
      text-transform: uppercase;
    }

    .user-email {
      color: $brand-slate-blue;
      margin-top: -8px;
      margin-right: 16px;
    }

    .ellipsis {
      flex-wrap: wrap;
      overflow: hidden;

      & > div:last-child {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.users-permission-modal-wrapper {
  .user-data-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dropdown-list {
      width: 200px;
    }
  }
}

.users-permission-modal {
  &.info-open {
    height: 100px;
  }
}

.modal-invite-user {

  &.modal-dialog {
    max-width: 600px;
  }

  .modal-header {
    display: flex;
    flex-direction: column;

    h2 {
      font-size: 22px;
      margin: 0 0 12px 0;
      color: $brand-slate-blue;
      font-weight: bold;
    }

    h4 {
      font-size: 16px;
      margin: 0;
      color: $brand-slate-blue-medium;
    }
  }

  .modal-body {
    label {
      font-size: 16px;
      font-weight: normal;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;

    .button-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      button {
        &:first-child {
          margin-right: 12px;
        }
      }
    }
  }
}