.modal .custom-confirm .modal-content {
  .modal-header {
    padding-left: 80px;
    padding-right: 80px;

    .modal-title {
      font-size: 1.5rem;
    }
  }
  .modal-body {
    padding: 30px 80px;

    .custom-confirm-body {
      p, a, b {
        font-size: 1.25rem;
      }
      .body-action-details {
        margin-bottom: 28px;
      }
      .body-message {
        text-align: justify;
        margin-bottom: 0;
      }
    }
  }
  .modal-footer {
    border-top: none;
  }
}