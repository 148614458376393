@import '~@admobilize/ui-kit/variables';

.btn-group button.btn {
  background-color: $brand-grey-light;
  color: $brand-slate-blue;
  font-size: 16px;
  font-weight: 400;

  &:hover {
    &:not(:disabled){
      background-color: $status-secondary-darker;
    }
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-group button.active {
  background-color: $brand-blue-medium;
  color: white;

  &:hover {
    background-color: $brand-blue-medium;
  }
}

.btn-group button:disabled {
  cursor: not-allowed;
}

.form-group div.btn-group {
  display: block;
  width: 100%;
}

