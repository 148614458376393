@import '~@admobilize/ui-kit/variables';

.textarea-wrapper {
  width: 100%;

  textarea {
    color: $text-dark;
    font-family: monospace;
    letter-spacing: 1px;
  }

  .form-control:disabled,
  .form-control[readonly] {
    cursor: not-allowed;
  }
}