@import "~@admobilize/ui-kit/variables";

.custom-control.custom-switch {
  &.danger {
    .custom-control-input:checked ~ .custom-control-label {
      &::before {
        background-color: $status-danger
      }
      &:hover {
        &::before {
          background-color: $status-danger
        }
      }
    }

    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 0.2rem rgba(239, 86, 88, 0.25)
    }
  }

  &.dinamic-label-color {
    .custom-control-label {
      color: #A1A7B3;

      &.checked {
        color: $status-danger;
      }
    }
  }
}



