@import '~@admobilize/ui-kit/variables';

.modal {
  .custom-report {
    max-width: 900px;

    .modal-content {
      .modal-body {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .wizard {
          display: flex;
          flex-direction: column;
          padding: 0;
          width: 100%;
          height: 100%;

          .wizard-navigation {
            display: flex;
            flex-direction: row;

            padding: 0;
            max-width: 100%;

            .wizard-step {
              margin: 0;
              padding: 12px;
              width: 275px;

              &:not(:last-child) {
                margin-right: 12px;
              }

              &.default {
                &:hover {
                  border: 1px solid #cfd5e6;
                }

                &::after {
                  border: none;
                }
              }

              &.selected {
                &::before,
                &::after {
                  border: none;
                }
              }

              &.completed {
                box-shadow: none;
                border: 1px solid #cfd5e6;
              }
            }
          }

          .wizard-content-container {
            flex: none;
            max-width: 100%;

            .wizard-content {
              padding: 0;
              margin-top: 24px;
              box-shadow: none;

              .step-content {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                max-height: unset;
                overflow-y: unset;

                .form-group {
                  width: 45%;
                }
              }

              .buttons {
                justify-content: flex-end;

                button {
                  &:last-of-type {
                    margin-left: 12px;
                  }
                }
              }
            }
          }
        }

        .form-group {
          .dropdown,
          .dropdown button {
            width: 100%;
          }

          .period-selector {
            .DateRangePicker {
              .DateRangePickerInput {
                display: flex;
                align-items: center;
                height: 40px;

                .DateInput.DateInput__small {
                  width: 110px;

                  input {
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }

      .modal-footer {
        button {
          min-width: 120px;
          margin: 0;
        }
      }
    }
  }

  .quick-report {
    .modal-content {
      .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          margin-bottom: 24px;
        }

        h2 {
          text-align: center;

          &.recurring-report {
            width: 80%;
          }
        }

        .btn {
          color: #ffffff;
          background-color: #2f64f5;
          border-color: #2f64f5;
          span {
            color: white;
          }
        }

        p,
        span {
          color: #767e9a;
        }

        .quick-report-info {
          margin: 24px 0;

          > div {
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            img {
              margin-bottom: 0;
              margin-right: 12px;

              width: 18px;
              height: 18px;
            }
          }

          span {
            color: #767e9a;
          }
        }

        .form-group {
          width: 100%;

          label {
            margin-bottom: 12px;
          }
        }

        button {
          align-self: flex-end;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .custom-report {
      max-width: 90%;
    }
  }
}
