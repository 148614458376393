@import '~@admobilize/ui-kit/variables';

#header {
  border-bottom: 1px solid #DAE0F2;
  height: 92px;

  .header-title {
    display: flex;
    padding: 24px;
    height: 87px;

    img {
      margin-right: 12px;
    }

    h1 {
      font-size: 33px;
      color: $text-dark;
    }
  }

  .header-tools {
    display: flex;
    padding: 24px;
    padding-left: 0px;
    align-items: center;

    .filters {
      align-items: center;
      display: flex;

      &::before {
        content: "";
        width: 1px;
        height: 27px;
        background-color: #D5DAE8;
        display: flex;
        margin-right: 24px;
      }

      .project-selector {
        margin-right: 12px;

        .react-select-virtualized {
          > div {
            &:nth-of-type(2) {
              width: 500px;
            }
          }
        }
      }
    }

    .header-actions {
      align-items: center;

      & > button {
        &:not(:last-child) {
          margin-right: 30px;
        }
      }

      .reload-icon {
        overflow: hidden;
        transition-duration: 0.8s;
        transition-property: transform;

        &:hover {
          transform: rotate(360deg);
          -webkit-transform: rotate(360deg);
        }
      }
    }
  }

  @media (max-width: 992px) {
    .header-title {
      img {
        margin-right: 0;
      }

      h1 {
        display: none;
      }
    }

    .filters {
      .dropdown-label {
        display: none;
      }

      .project-selector .dropdown-search .react-select__dropdown {
        width: 200px;
      }

      .react-selector__menu {
        left: -80px;
      }

      // Overwrite menu position on smallest screens
      .dropdown-menu.show {
        left: auto !important;
        right: 0 !important;
        top: 42px !important;
        transform: none !important;
      }
    }
  }
}