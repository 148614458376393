@import '~@admobilize/ui-kit/variables';

#create-devices-modal {
  textarea {
    resize: none;
    margin-bottom: 4px;
    cursor: default;
  }
  .modal-content {
    .modal-header {
      .modal-title {
        p {
          font-weight: 400;
        }
      }
    }
    .modal-body {
      .create-device {
        .dropdown-list-container {
          width: 100%;
          &.dropdown {
            width: 100%;
            button {
              width: 100%;
            }
          }
        }
      }
      .provision-device {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 54;
        gap: 8px;

        img {
          height: 80px;
          width: 80px;
        }
      }

      .os-step {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .instruction {
          display: flex;
          gap: 4px;
        }

        .code-snippet {
          display: flex;
          flex-direction: column;

          .btn {
            align-self: flex-end;
          }
        }
      }

      .file-input-container {
        display: flex;
        align-items: center;
    
        #input-file-btn {
          display: none;
        }
    
        .file-info {
          margin-left: 12px;
          overflow: hidden;
          overflow-wrap: break-word;
        }
    
      }
    }
    .modal-footer {
      gap: 8px;
    }
  }
}
