@import '~@admobilize/ui-kit/variables';

.profile-cards {
  .card {
    .card-header {
      justify-content: flex-start;
    }
  }

  &.user-profile {
    height: 79.25%;
    align-self: flex-start;

    .buttons-container {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      height: 100%;
      width: 100%;

      .btn {
        &:not(:last-child) {
          margin-right: 12px;
        }
      }
    }

    .form-group {
      margin-bottom: 12px;

      .input-wrapper {
        button {
          padding: 0;
        }
      }
    }
  }

  &.helpful-links {
    height: 19.25%;
    align-self: flex-start;

    .card {
      .card-body {
        display: flex;
        flex-direction: row;
        align-items: center;

        padding: 12px 20px;
      }
    }

    .link {
      &:nth-child(1) {
        width: 40%;
      }
      &:nth-child(2) {
        width: 60%;
      }

      h4 {
        color: #455079;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 4px;
      }
    }
  }

  &.preferences {
    height: 100%;
    align-self: flex-end;

    .project-token-container {
      margin: 24px 12px 12px;

      #project-token {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .info-box {
      background-color: #f0f2f7;
      padding: 12px 24px;
      border-radius: 5px;

      img {
        margin-right: 8px;
      }

      span {
        color: #767E9A;
      }
    }

    .settings-control {
      padding-top: 20px;

      .button-container {
        display: flex;
        justify-content: flex-end;

        a {
          margin-right: 12px;
        }
      }

      .preference-control {
        > div:not(:last-of-type) {
          margin-bottom: 25px;
        }

        .form-row {
          label {
            font-weight: normal;
          }

          .form-group {
            margin-bottom: 0;
          }
        }
      }
    }

    .settings-loading {
      margin-top: 20px;
    }

    .preference-control {
      padding: 20px 0;

      &:not(:last-child) {
        border-bottom: 1px solid #E5E5E5;
      }

      &.mask-toggle {
        display: flex;
        justify-content: space-between;
        align-items: center;

        label {
          margin-bottom: 0;
        }
      }

      &.project-selector {
        label {
          display: block;
          margin-bottom: 0;
        }

        .dropdown-search {
          margin-top: 12px;

          .react-select__dropdown {
            .btn {
              &.btn-secondary {
                &.active {
                  color: #455079;
                  background-color: #D1D1D1;
                  border-color: #D1D1D1;
                  box-shadow: 0 0 0 0.2rem #E5E5E5;
                }
              }

              span {
                font-weight: normal;
              }
            }

            .react-selector__menu {
              left: 0;
              right: auto;
              margin: .3rem 0 0;
              width: 500px;

              .react-select-virtualized {
                > div:nth-of-type(2) {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      label {
        font-weight: bold;
        margin-bottom: 12px;
      }
    }
  }

  @media (max-width: 1200px) {
    &.user-profile, &.helpful-links, &.preferences {
      height: auto;
    }

    &.projects {
      height: 400px;
    }
  }
}