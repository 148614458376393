.dropdown-menu-list {
  .dropdown {
    .dropdown-menu {
      min-width: 250px;
      min-height: 135px;
      padding: 20px;

      button {
        width: 100%;
      }

      .form-group {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}