@import '~@admobilize/ui-kit/variables';
.sitegroups-container {
  .custom-card {
    margin: 0px 6px;
    .card-header {
      min-height: 22px;
    }
    .card-body {
      margin: 12px 0px;
      h4 {
        font-size: 14px;
        max-width: 300px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        
        b {
          font-size: 14px;
        }
      }
    }
    .card-footer {
      img {
        height: 20px;
      }
    }
  }

  .blue-icon {
    // The original icon should be #455079 so after applying the filter
    // it will become #326df5 the closest we got from #2F64F5
    filter: saturate(2.7) brightness(1.32) hue-rotate(354deg);
  }
}