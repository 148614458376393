@import '~@admobilize/ui-kit/variables';

$page-offset: 183px;
$tabs-header : 88px;
$tabs-padding: 96px;
$toolbar: 67px;
$footer-offset: 48px;


.device-explorer {
  .toolbar {
    width: 100%;
    padding: 12px 0px;
    gap: 24px;
    height: 67px;

    .search-box {
      width: 100%;
    }
  }

  .device-list-container {
    display: flex;
    align-items: center;
    height: calc(100vh - #{$page-offset} - #{$tabs-header} - #{$tabs-padding} - #{$toolbar} - #{$footer-offset});
    overflow: auto;

    .device-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 8px;
      background: #EFEFEF;
      border: 1px solid #D1D1D1;
      border-left: 3px solid #50C150;

      cursor: pointer;

      &.online {
        border-left: 3px solid #50C150;

        .platform {
          filter: saturate(1.8) brightness(2.06) hue-rotate(248deg);
        }
      }

      &.offline, &.archived {
        border-left: 3px solid #EF5658;

        .platform {
          filter: saturate(1.9) brightness(1.5) hue-rotate(120deg);
        }
      }

      &.stopped, &.no-camera, &.updating {
        border-left: 3px solid #FFA30F;

        .platform {
          filter: saturate(5.7) brightness(2.8) hue-rotate(200deg);
        }
      }

      &:hover {
        background-color: white;
        border-left-width: 10px;
        transition: border-left-width 0.1s ease;
      }

      .display-name {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $text-dark;
        font-size: 14px;
        font-weight: 700;

        img {
          margin-right: 8px;
        }
      }

      .extra-data {
        display: flex;
        align-items: center;
        gap: 24px;
      }

      .project {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 500;
        color: $text-dark;
        width: 200px;

        img {
          margin-right: 8px;
        }
      }

      .version {
        font-size: 10px;
        font-weight: 900;
        color: $text-dark;
      }

      &.selected {
        border-left-width: 10px;
        background-color: white;

        .display-name {
          color: $brand-blue-medium;
        }
      }
    }

    .device-grid {
      .extra-data {
        display: none;
      }
    }
  }

}