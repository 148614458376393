@import '~@admobilize/ui-kit/variables';

$page-offset: 92 + 91; // Page header + Toolbar
$drawer-height: 39 + 24; // Drawer Header + Paddings
$accordion-header-height: 36;
$accordion-item-margin: 6;
$accordion-items-quantity: 4;
$accordion-height: $accordion-header-height + $accordion-item-margin;

$accordion-height-offset: $drawer-height + ($accordion-items-quantity * $accordion-height);

.sitegroups {
  .no-data-message {
    height: calc(100vh - 91px - 92px);
  }
  .component-loading {
    height: calc(100vh - 91px - 92px);

    img {
      max-width: 150px;
    }
  }

  .sitegroups-container {
    display: flex;
    margin: 0px 24px;

    #drawer {
      .details-drawer-header {
        padding: 6px 12px;
        margin-bottom: 6px;
      }
      .details-drawer-body {
        .accordion {
          .accordion-item {
            margin-bottom: 6px;
            &:last-child {
              margin-bottom: 0px;
            }

            .item-header {
              padding: 6px 12px;
            }

            &.is-expanded {
              .item-body {
                height: calc(100vh - #{$accordion-height-offset}px - #{$page-offset}px);

                .form-group {
                  margin-bottom: 12px;
                }

                .site-group-name {
                  .input-wrapper {
                    width: 100%;
                  }
                }

                .site-group-integration {
                  .btn {
                    width: 100%;

                    div {
                      justify-content: center;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    textarea {
      resize: none;
      height: 128px;
      font-family: 'Lato', sans-serif;
      letter-spacing: 0px;
      border-color: #455079;

      &:disabled {
        background-color: transparent;
        border-color: transparent;
        padding: 0px;
      }
    }

    .multiselector {
      .item-list {
        border: none;
        border-top: 1px solid $brand-grey;
        padding: 0px;

        .item {
          .action-buttons {
            display: none;
            .icon-button {
              border: none;
              background-color: transparent;
            }
          }

          &:hover {
            .action-buttons {
              display: flex;
            }
          }
        }
      }
    }
  }
}

.modal {
  .modal-transfer-sites {
    .modal-content {
      padding: 24px;
      .modal-body {
        padding: 12px;
        .transfer-sites {
          h3 {
            font-size: 22px;
            font-weight: bold;
            color: $text-dark;
            margin-bottom: 4px;
          }

          h4 {
            font-size: 16px;
            color: $brand-slate-blue-medium;
            margin-bottom: 24px;
          }
        }
        .button-group {
          display: flex;
          justify-content: flex-end;
          padding-top: 24px;
          border-top: 1px solid #cfd5e6;
          margin-top: 24px;

          button {
            margin-left: 24px;
          }
        }
      }
    }
  }
}

.sitegroup-creation {
  .wizard {
    .wizard-content-container {
      .wizard-content {
        .add-sites {
          overflow-y: hidden;
          height: calc(100vh - 388px);
        }

        .add-users {
          overflow-y: hidden;
        }

        .add-integrations {
          min-height: calc(100vh - 325px);
        }

        .wizard-content-description {
          margin-bottom: 12px;
        }
        .info-section {
          padding: 0;

          .edit-button-container,
          .site-group-owner,
          .site-group-company,
          .site-group-integration {
            display: none;
          }

          textarea {
            font-family: 'Lato', sans-serif;
            resize: none;
            border-color: $brand-slate-blue;

            &:focus {
              box-shadow: none;
              border-color: $brand-slate-blue;
            }
          }
        }

        .integration-section {
          padding: 12px;
          min-height: 320px;

          .custom-dropdown {
            width: 100%;

            .dropdown-list-container {
              width: 100%;

              .dropdown-list {
                width: 100%;
              }
            }
          }

          .left-button {
            margin-right: 24px;
          }
        }

        .multiselector {
          height: 100%;
          .search-filter-bar {
            .search-box {
              box-shadow: none;
              border: 1px solid #455079;
              padding-right: 12px;
            }
          }

          .toolbar {
            .btn {
              display: none;
            }
          }

          .item-list {
            padding: 0;
            border: none;
            border-top: solid 1px $brand-grey;
            height: calc(100% - 90px);

            .item {
              border-top: solid 1px $brand-grey-light;
              padding: 8px 16px;

              &:first-child {
                border-top: none;
              }

              .cell {
                flex: 1;
                text-align: center;
                color: $brand-slate-blue-medium;
              }

              .checkbox-wrapper {
                width: 100%;
                height: 100%;

                img {
                  height: 30px;
                  width: 30px;
                }
              }
            }
          }
        }

        .new-users {
          height: calc(100vh - 388px);

          .new-users-header {
            min-height: 68px;
            display: flex;
            justify-content: space-between;
            gap: 12px;
            margin-bottom: 12px;

            .email {
              width: 100%;
              flex: 2;

              input {
                height: 43px;
              }

              div {
                width: 100%;
              }
            }

            .input-wrapper {
              width: 100%;
              .form-control {
                border-color: $brand-slate-blue;
                &:focus {
                  box-shadow: none;
                  border-color: $brand-slate-blue;
                }
              }
            }
          }

          .new-users-list {
            min-height: 80%;
            max-height: calc(100% - 82px);
            overflow-y: auto;
            .user-row {
              display: flex;
              padding: 16px;
              border-bottom: solid 1px $brand-grey-light;
              justify-content: space-between;
              max-height: 72px;

              .user-info {
                display: flex;
                flex-direction: column;
                flex: 1;

                .role {
                  color: $brand-blue-medium;
                  font-size: 10px;
                }

                .email {
                  color: $brand-slate-blue;
                  font-weight: bold;
                }
              }

              .action-buttons {
                display: none;

                .role-buttons {
                  position: relative;
                  top: 70px;
                  left: 77px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid $brand-slate-blue;
                  padding: 12px 4px;
                  border-radius: 5px;
                  background-color: white;
                  z-index: 99;

                  .role-button {
                    padding: 8px 24px;
                    color: $brand-slate-blue;
                    cursor: pointer;

                    &:hover {
                      background-color: #ebf1ff;
                      color: #2f64f5;
                    }
                  }
                }
              }

              &:hover {
                background-color: $brand-grey-light;

                .action-buttons {
                  display: flex;
                  flex: 1;
                  align-items: center;
                  justify-content: flex-end;
                  gap: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}
