@import '~@admobilize/ui-kit/variables';

.details-drawer {
  display: flex;
  flex-direction: column;
  width: 400px;
  min-width: 400px;

  border-radius: 5px;

  &.hidden {
    display: none;
  }

  .details-drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 7px 20px;
    margin-bottom: 1px;

    border: none;
    background: none;

    h3 {
      font-size: 16px;
      font-weight: bold;
      line-height: inherit;

      color: $brand-slate-blue;
      margin: 0;
    }

    .btn {
      padding: 0 2px;

      i {
        color: $brand-slate-blue;

        &::before {
          font-weight: bold;
          margin: 0;
        }
      }
    }
  }

  @media (max-width: 1440px) {
    width: 370px;
    min-width: 370px;
  }
}

#manage-zones-modal {
  max-width: fit-content;
}