.icon-switch {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  border-radius: 5px;
  border: 1px solid #EDEFF5;

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    min-width: 30px;
    height: 30px;
    cursor: pointer;

    label {
      font-size: 14px;
      color: #767E9A;
      margin-right: 8px;
      cursor: pointer;
    }

    img {
      filter: saturate(0.4) brightness(1.6) hue-rotate(0deg);
    }

    &.selected {
      background-color: #E1E7F5;
      border-radius: 5px;

      label {
        color: #2F64F5;
      }

      img {
        filter: saturate(2.7) brightness(1.32) hue-rotate(354deg);
      }
    }
  }
}