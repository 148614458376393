@import "~@admobilize/ui-kit/variables";

.notification-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  & > div {
    overflow-y: auto;
  }

  & > button {
    margin: 5px;
    color: #2f64f5;
    font-weight: bold;
  }

  .no-notification {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h3 {
      margin-top: 20px;
    }

    p {
      color: #767e9a;
    }
  }
}
