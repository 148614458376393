@import '~@admobilize/ui-kit/variables';

#nav-user {
  height: 60px;

  .close-btn-container {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px;

    img {
      width: 18px;
      height: 18px;
    }
  }

  #user-info {
    margin-bottom: 0;
    padding: 0px 48px 24px;
    color: $text-dark;

    h5 {
      font-size: 16px;
      font-weight: bold;
      margin: 8px 0 0;
    }
    .stop-impersonating-button {
      font-size: 12px;
      font-weight: bold;
      margin-top: 10px;
      border: 1px solid;
      text-align: center;
      padding: 3px;
      cursor: pointer;

      &:hover {
        color: $brand-grey-light;
        background: $text-dark;
      }
    }
  }

  .items-container {
    padding-bottom: 12px;

    .dropdown-menu {
      padding: 0;
    }

    .dropdown-submenu {
      cursor: pointer;
      margin-bottom: 0;

      &.external {
        background: none;
      }

      .dropdown-header {
        background: none;
        border: none;
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding: 12px 48px;
        font-weight: normal;
        justify-content: space-between;
        text-decoration: none;
        color: $text-dark;

        &:hover {
          background: $brand-blue-light;
          color: $text-dark;
        }

        >div {
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
            height: 16px;
          }
        }


        .external-icon {
          height: 12px;
        }
      }
    }
  }

}