@import '~@admobilize/ui-kit/variables';

.checkbox-wrapper {
  display: flex;
  align-items: center;
  border-radius: 5px;

  .custom-checkbox-label {
    color: $text-dark;
    cursor: pointer;

    font-weight: normal;
    font-size: 16px;

    margin-right: 10px;
    margin-bottom: 0;
  }

  .custom-checkbox {
    border-radius: 5px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 24px;
    height: 24px;
    margin-right: 8px;
    border: 2px solid $text-dark;
    background: white;

    &.selected {
      background-color: $brand-blue-medium;
      border-color: $brand-blue-medium;
    }

    .uil-check, .uil-minus {
      &:before {
        font-size: 20px;
        margin: 0;
        font-weight: bold;
      }
    }
  }

  .custom-checkbox.selected {
    background: $brand-blue-medium;
  }

  .custom-checkbox i {
    font-size: 0.7em;
  }

  &:hover {
    cursor: pointer;
    background: #F0F2F7;

    .custom-checkbox {
      background-color: #F0F2F7;
    }

    .custom-checkbox.selected {
      background: $brand-blue-medium;
    }

    label {
      cursor: pointer;
    }
  }

  &.disabled {
    background: none;
    opacity: 0.5;
    cursor: not-allowed;

    span, label {
      background: none;
      cursor: not-allowed;
    }
  }
}