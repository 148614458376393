@import '~@admobilize/ui-kit/variables';

/*
  - header: 92px
  - container: 48px
  - toolbar: 91px
  - tabs header: 88px
*/
$offset: 319px;

.tabs {
  width: 100%;
  box-shadow: 0px 3px 9px rgba(23, 50, 169, 0.051);
}

.tabs-header {
  display: flex;
  justify-content: space-between;
  background: none;
  padding: 0px;

  .tabs-button-container {
    display: flex;
    width: 100%;
  }

  .btn-tab {
    color: $brand-slate-blue-medium;
    background: #f0f2f7;
    height: 88px;
    padding: 24px;
    border-right: 1px solid $brand-grey;
    border-radius: 5px 5px 0px 0px;
    outline: none !important;

    //has() is not supported in some browsers, but this won't affect  the usability
    &:last-child,
    &.selected,
    &:has(+ .selected) {
      border-right: 1px solid transparent;
    }

    &:focus {
      box-shadow: none;
    }

    &:hover {
      color: $text-dark;
      font-weight: bold;
    }

    .tab-title {
      display: flex;
      align-items: center;
    }

    .tab-icon {
      background-color: #fff;
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      margin-right: 12px;

      img {
        width: 20px;
        height: 20px;
        filter: saturate(2.7) brightness(1.32) hue-rotate(354deg);
      }
    }

    .tab-title-name {
      font-size: 18px;
      font-weight: normal;
      margin: 0px;
      color: #455079;
    }

    .tab-title-description {
      font-size: 16px;
      font-weight: normal;
      color: #767e9a;
      margin: 0px;
    }

    &.selected {
      background: white;
      border-bottom: 1px solid transparent;
      color: $text-dark;

      font-weight: bold;

      .tab-icon {
        background-color: #2f64f5;

        img {
          filter: brightness(100);
        }
      }
    }

    &.notify {
      position: relative;
      &::after {
        width: 10px;
        height: 10px;
        background-color: $status-danger;
        border-radius: 50%;
        content: '';
        display: block;
        top: 20px;
        right: 20px;
        position: absolute;
        border: 1px solid $status-danger;
      }
    }

    .btn.btn-open-modal {
      padding: 0.2rem 0.75rem;
      color: $text-dark;
      font-weight: bold;
      background-color: $brand-grey-light;
      border-radius: 5px;
    }

    .btn-open-modal:hover {
      &:not(.disabled) {
        color: $brand-grey-light;
        background-color: $text-dark;
      }

      &.disabled {
        cursor: not-allowed;
      }
    }
  }
}

.tabs-body {
  margin: 0;
  padding: 48px;
  border-radius: 0px 0px 5px 5px;
  height: calc(100vh - #{$offset});
  overflow-y: auto;
  background: white;

  h2 {
    margin-bottom: 24px;
    font-weight: normal;
  }
}
