@import '~@admobilize/ui-kit/variables';

.parent-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.scrollable-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  /*
   * 92px: app-header height
   * 91px: filter-bar height
   * TOTAL = 193px -> container offset
   */
  height: calc(100vh - 183px);
  overflow-y: auto;
}

.filter-bar {
  padding: 24px;
}

.no-data-message {
  display: flex;
  align-items: center;
  justify-content: center;
  /*
   * 92px: app-header height
   * TOTAL = 92px -> container offset
   */
  height: calc(100vh - 92px);
  color: $brand-slate-blue;
}