.react-table {
  &.has-action-buttons {
    table {
      thead {
        &.has-bulk-actions {
          tr {
            th {
              &.bulk-actions {
                display: table-cell;
                height: 100%;
                width: 250px;
                margin-right: 16px;

                .commands-list {
                  display: flex;

                  .icon-button {
                    margin-left: 12px;
                  }
                }
              }

              &:not(:last-of-type):nth-last-child(-n + 2) {
                display: none;
              }
            }
          }
        }
      }

      tbody {
        tr {
          &:hover {
            td {
              &.action-buttons {
                display: table-cell;
                height: 100%;
                width: 244px;
              }

              &:not(:last-of-type):nth-last-child(-n + 2) {
                display: none;
              }
            }
          }
        }
      }

      thead,
      tbody {
        tr {
          th,
          td {
            &.action-buttons,
            &.bulk-actions {
              display: none;
              justify-content: flex-end;
              align-items: center;
              padding: 8px 12px;

              .btn-secondary {
                display: flex;
                justify-content: center;
                align-items: center;

                margin-right: 8px;
                padding: 8px;

                &:last-of-type {
                  margin-right: 0;
                }

                i {
                  &::before {
                    font-size: 1.2rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  table {
    thead,
    tbody {
      tr {
        th,
        td {
          .cell-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          .checkbox-wrapper {
            margin-right: 24px;

            .custom-checkbox,
            .custom-checkbox i {
              margin-right: 0;
            }
          }
        }
      }
    }

    tbody {
      tr {
        &.loading-message {
          display: flex;
          align-items: center;
          justify-content: center;

          height: 100%;

          &:hover {
            background-color: transparent;
            cursor: default;
          }
        }
      }
    }

    .navigation-container {
      display: flex;
      align-items: center;
      margin: 24px 0;

      button {
        margin-right: 8px;
      }
    }
  }
}

.pagination-wrapper {
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    font-size: 16px;

    /*
     * 76px: sidebar-navigation width
     * 48px: container padding
     * TOTAL = 124px -> footer offset
     */
    width: calc(100% - 124px);

    .current-year {
      font-size: 16px !important;
    }
  }
}
