@import '~@admobilize/ui-kit/variables';

.site-card {
  border: 1px #d1d1d1 solid;
  border-radius: 10px;
  height: 180px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .body {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px #d1d1d1 solid;
    flex-grow: 2;

    img {
      height: 60px;
      width: 60px;
    }
  }

  .card-footer {
    background-color: transparent;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 48px;
    border-top: none;
    color: $brand-slate-blue;
    font-size: 16px;
    font-weight: 700;
  }

  &:hover {
    cursor: pointer;

    .body {
      img {
        filter: saturate(2.7) brightness(1.32) hue-rotate(354deg);
      }
    }

    .card-footer {
      background-color: $brand-blue-medium;
      color: white;
      border-radius: 0px 0px 10px 10px;
      border-color: $brand-blue-medium;
      border-top: none;
    }
  }
}
