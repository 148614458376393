@import '~@admobilize/ui-kit/variables';

.input-error {
  color: $status-danger !important;
}

.sites,
#manage-sites-modal {
  .step-content,
  .tabs-body {
    max-height: calc(100vh - 310px);

    .data-sources {
      min-height: 45px;
    }

    .section-label {
      margin: 4px 0 12px 0;
      margin-bottom: 12px;
    }

    .step-description {
      font-size: 16px;
      margin: 0;
      margin-bottom: 24px;
      color: #767e9a;
    }

    .description-field {
      textarea {
        resize: none;
        height: 128px;
        font-family: 'Lato', sans-serif;
        letter-spacing: 0px;
        border-color: #455079;
      }

      .form-control:focus {
        box-shadow: none;
        border-color: #455079;
      }
    }

    .custom-solution-options {
      margin-top: 12px;
      padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .data-source-item {
        display: flex;
        flex: 0 0 50%;
        max-width: 50%;
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-bottom: 16px;
      }
    }

    .site-device-section {
      display: flex;
      align-items: center;
      padding-top: 24px;
      gap: 12px;

      .dropdown-search {
        width: 100%;
      }
    }

    .device-tags {
      display: flex;
      align-content: flex-start;
      padding: 12px;
      min-height: 300px;
      flex-wrap: wrap;
      border: solid $brand-grey-medium 1px;
      border-radius: 5px;
      .tag {
        height: 40px;
      }
    }

    .dropdown-list-container.dropdown {
      width: 100%;
      button.dropdown-list.btn.btn-secondary {
        width: 100%;
      }
    }

    .row {
      margin-right: 0px;
    }
    .input-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .input-icon {
        height: 20px;
      }
    }

    .map-container {
      width: 100%;
      height: 300px;
    }

    .custom-card-buttom {
      //override customRadioButton
      margin: 24px 0px;
      .btn-group {
        display: flex;
        justify-content: center;
        width: 100%;
        grid-gap: 12px;

        .btn {
          background-color: transparent;
          padding: 0px;
          flex: 1 1 0;

          &.active {
            .site-card {
              .card-footer {
                background-color: $brand-blue-medium;
                color: white;
                border-radius: 0px 0px 10px 10px;
                border: 1px solid $brand-blue-medium;
                border-top: none;
              }
              img {
                filter: saturate(2.7) brightness(1.32) hue-rotate(354deg);
              }
            }
          }
        }

        .btn:hover:not(:disabled) {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }

  .wizard-content {
    min-height: 470px;

    .wizard-content-description {
      //override wizard description.
      display: none;
    }
  }

  .wizard {
    .wizard-content-container {
      .wizard-content {
        .step-content {
          max-height: calc(100vh - 310px);
        }
      }
    }
  }
}

.pac-container {
  &.pac-logo {
    z-index: 2000;
  }
}
