@import '~@admobilize/ui-kit/variables';
/*
  * 51px: footer height
  * 24px: side-menu top & bottom padding
  * 91px: filter-bar height
  * 92px: app-header height
  * TOTAL = 258px -> accordion offset
  */
$accordion-offset: 258px;

.analytics-container {

  .no-data-message {
    width: 100%;
    height: calc(100vh - 183px);
  }

  .side-panel-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .side-menu {
      height: 100%;

      .accordion {
        height: 100%;
      }

      .accordion-item.is-expanded {
        .item-body {
          height: 100%;
          overflow-x: hidden;

          .multiselector {
            .search-filter-bar {
              .dropdown {
                .dropdown-menu.show {
                  width: 300px;
                }
              }
            }
          }

          .multiple-filter {
            .multiple-filter-filters {
              height: auto;
              .multiselector {
                .item-list {
                  height: auto;
                }
              }
            }
          }
        }
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: center;

      box-shadow: 0px 2px 6px #00000012;
      background-color: $brand-grey-light;
      padding: 1rem;
    }
  }
}

#analytics-widget-container {
  flex: 1;
  box-shadow: inset 2px 0 6px #00000012;

  .filter-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    #side-menu-toggle {
      display: block;
      margin-right: 12px;

      i {
        display: flex;

        &::before {
          font-weight: bold;
        }
      }
    }

    .report-dropdown {
      .dropdown-list-container {
        > button {
          background: #2f64f5;
          color: white;
          font-weight: bold;
          width: 200px;

          img {
            filter: brightness(100);
          }
        }
      }

      .dropdown-menu {
        min-width: 200px;
      }

      .custom-dropdown {
        .dropdown-menu.show {
          width: 205px;
        }
      }
    }

    #custom-widget-button {
      width: 43px;
      height: 43px;
      margin-left: 24px;
    }
  }

  #analytics-chart-container {
    padding: 0 12px 12px 0px;

    .chart-row {
      &:last-child {
        padding-bottom: 0;
      }
    }

    .highcharts-text-outline {
      font-size: 11px;
    }
  }

  @media (max-width: 1440px) {
    #analytics-chart-container {
      &.expanded-sidebar {
        .chart-row {
          flex-wrap: wrap;

          &:first-child {
            widget-container {
              width: 20%;

              // :nth-child(5) is used because this is a specific fix
              // and cannot be made as a general purpose solution
              &:nth-child(5):last-child {
                width: 100%;
                margin-top: 12px;
              }

              &:not(:last-child) {
                margin-right: 12px;
                margin-bottom: 0;
              }

              // :nth-last-child(2) stands for penultimate element
              // :nth-child(4) is used because this is a specific fix
              // and cannot be made as a general purpose solution
              &:nth-child(4):nth-last-child(2) {
                margin-right: 0;
              }
            }
          }

          widget-container {
            flex: auto;
            width: 100%;
            margin-right: 0;

            &:not(:last-child) {
              margin-bottom: 12px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .filter-bar {
      #side-menu-toggle {
        margin-right: 6px;
      }

      .date-time-filter-bar {
        .timezone-selector {
          margin-right: 6px;
          width: 180px;

          button {
            padding-left: 6px;
            padding-right: 6px;
          }
        }
        .period-selector {
          .DateRangePickerInput_calendarIcon {
            margin-left: 5px;
            padding-left: 0;
            padding-right: 0;
          }
          .DateRangePickerInput_arrow {
            margin: 0;
          }
          .DateRangePickerInput {
            padding-right: 5px;
          }
        }
        .interval-selector {
          margin-left: 6px;
        }
      }

      .report-button {
        > button:not(:last-child) {
          margin-right: 6px;
        }
      }
    }

    #analytics-chart-container {
      &.expanded-sidebar {
        .chart-row {
          flex-wrap: wrap;

          &:first-child {
            widget-container {
              flex: auto;
              width: 48%;

              margin-top: 0;

              &:last-child,
              &:nth-child(odd):nth-last-child(2) {
                margin-bottom: 0;
              }

              &:nth-child(5):last-child {
                margin-top: 0;
              }

              &:not(:last-child) {
                margin-right: 0;
                margin-bottom: 12px;
              }

              &:nth-child(odd):not(:last-child) {
                margin-right: 12px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    .filter-bar {
      #side-menu-toggle {
        padding: 6px 10px;
      }
      .date-time-filter-bar {
        .timezone-selector {
          width: 158px;
          button {
            padding-left: 4px;
            padding-right: 4px;
            div span {
              padding: 5px;
            }
          }
        }
        .interval-selector {
          li {
            margin: 0;
          }
        }
      }
      .report-button {
        button {
          padding: 6px 10px;
          margin-right: 0;
          span {
            display: none;
          }
        }
      }
    }
    #analytics-chart-container {
      .chart-row {
        flex-wrap: wrap;

        widget-container {
          flex: auto;
          width: 48%;
          margin-right: 0;

          &:nth-child(odd):not(:last-child) {
            margin-right: 12px;
          }
          &:not(:last-child) {
            margin-bottom: 12px;
          }
          &:nth-child(odd):nth-last-child(n-1) {
            margin-bottom: 0;
          }
        }
      }

      &.expanded-sidebar {
        .chart-row {
          &:first-child {
            widget-container {
              flex: auto;
              width: 100%;
              margin-right: 0;

              &:nth-child(odd):not(:last-child) {
                margin-right: 0;
              }

              &:nth-child(odd):nth-last-child(2) {
                margin-bottom: 12px;
              }
            }
          }

          &:not(:first-child) {
            widget-container {
              margin-right: 0;

              &:not(:last-child) {
                margin-bottom: 12px;
              }
            }
          }
        }
      }
    }
  }
}

.visibility-hidden {
  visibility: hidden;
  height: 0;
  width: 0;
}

.show-only-print {
  display: none;
}

@page {
  size: A4 auto;
}

@media print {
  .app {
    overflow: visible;
    position: initial !important;

    height: 100% !important;
    width: 100% !important;

    .show-only-print {
      display: block;

      .company-logo {
        padding: 0px 12px;
        margin: 32px 0px;

        .company-logo-title {
          margin-left: 12px;

          h2 {
            margin-bottom: 0;
          }
          h5 {
            margin-bottom: 0;
          }
        }

        img {
          width: 70px;
        }
      }
      .devices-name {
        h5 {
          margin-right: 4px;
        }
        .tag {
          margin-bottom: 10px;
          margin-right: 15px;

          .tag-text,
          .tag-label {
            margin-right: 0;
          }
        }
      }
    }

    #header,
    .sidebar-wrapper,
    .sidebar-wrapper .sidebar-navigation,
    .side-panel,
    #analytics-widget-container .filter-bar {
      display: none !important;
    }

    .scrollable-container {
      height: 100%;
      overflow: visible;
    }

    .app-container {
      .widgets-container .parent-container {
        display: block;
      }

      #analytics-chart-container {
        .chart-row {
          page-break-inside: avoid;
          flex-wrap: nowrap;
          padding: 0 !important;

          &:not(:last-child) {
            margin-bottom: 12px !important;
          }

          widget-container {
            flex: 1 1 !important;
            page-break-inside: avoid;
            -webkit-print-color-adjust: exact;

            .display-table, .stat-list-wrapper{
              height: auto !important;
              max-height: none ;
            }

            &:not(:last-child) {
              margin-right: 12px !important;
            }
          }

          .tooltiptext,
          .highcharts-tooltip {
            visibility: hidden !important;
          }
        }
      }
    }
  }
  .modal,
  .modal-backdrop {
    &.show {
      display: none !important;
    }
  }
}
