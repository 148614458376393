@import '~@admobilize/ui-kit/variables';

.details-drawer .details-drawer-body .accordion .accordion-item.is-expanded .item-body,
.tabs .tabs-body {

  .wrap-word {
    overflow-wrap: break-word;
    max-width: 90%;
  }

  .form-group {
    &.modify-preset {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 0;
      }

      small {
        color: $text-dark;
        margin-bottom: 12px;
      }
    }
    margin-bottom: 20px;
  }

  .datetime-info {
    display: flex;
    color: $brand-slate-blue;

    i {
      margin-right: 6px;
    }

    > div {
      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }

  .device-command {
    > p {
      color: $text-dark;
      margin-bottom: 20px;
    }

    .admin-button {
      margin-top: 12px;
    }

    .btn-secondary {
      width: 100%;

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  .accordion-item-section {
    .section-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      font-weight: bold;
      margin: 0 -12px 25px -12px;

      background-color: $brand-grey-light;
      border-radius: 5px;
      padding: 6px 12px;

      span {
        color: $brand-slate-blue;
      }
    }

    .section-body {
      color: $brand-slate-blue;

      .form-group {
        margin-bottom: 0;

        label {
          margin-bottom: 8px;
        }
      }

      .device-rotation, .preview-quality {
        > label {
          font-weight: bold;
        }
      }

      > div:not(:last-child) {
        margin-bottom: 25px;
      }

      .preview-quality {
        span {
          width: 60px;
          text-align: right;
          margin-left: 5px;
        }

        .threshold-range {
          width: 100%;
        }
      }

      .night-solution-options {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .form-group {
          width: 48%;

          &:last-child {
            width: 100%;
            margin-top: 12px;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  .section-body-button-container {
    display: flex;
    justify-content: flex-end;

    border-top: 1px solid $brand-grey-light;
    padding-top: 25px;
    margin-bottom: 12px;

    button:disabled {
      cursor: not-allowed;
    }
  }
}

.modal-add-model {
  .modal-body {
    padding: 30px!important;
  }
}

.modal-manage-zones {
  &.modal-dialog{
    max-width: fit-content;
  }
}

#support-only-device-settings {
  &:not(:disabled) {
    background: #1e1e1e;
    color: #8bc34a;
  }
}