@import '~@admobilize/ui-kit/variables';

.date-time-filter-bar {
  display: flex;
  align-items: center;

  .period-selector {
    .DateRangePicker.DateRangePicker_1 {
      height: 100%;

      > div {
        height: 100%;

        .DateRangePicker_picker.DateRangePicker_picker_1.DateRangePicker_picker__directionLeft.DateRangePicker_picker__directionLeft_2 {
          top: 44px !important;
        }

        .DayPicker.DayPicker_1 {
          > div {
            position: relative;
          }
        }

        .DateRangePickerInput.DateRangePickerInput_1 {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .custom-dropdown {
    margin-left: 12px;
  }

  .period-selector-filters {
    display: flex;
    margin-left: 12px;

    .tag {
      margin: 0;
    }
  }
}
