.time-parameters {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-height: unset;
  overflow-y: unset;

  .period-selector {
    width: 361px;
    border: 1px solid #455079;

    .time-range-filter {
      width: 100% !important;
    }
  }
}

.select-data {
  width: 100%;

  h3 {
    font-size: 16px;
    margin-bottom: 18px;
  }

  .csv-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .csv-column {
      width: 33%;

      .checkbox-wrapper {
        margin-bottom: 12px;

        .custom-checkbox-label {
          font-size: 16px !important;
          font-weight: normal !important;
        }
      }
    }
  }
}
