@import '~@admobilize/ui-kit/variables';

/*
 92px: App header height
 91px: App toolbar height
 24px: Sidebar Padding
 43px: Searchbox height
 12px: Searchbox margin
 
 TOTAL = 262px -> item selector container offset
 */
$item-selector-container-offset: 262px;

/*
 320px: sidepanel width
 96px: presets data padding
 32px: presets data margin
 24px: sidepanel margin
 76px: Sidebar

 Total = 548px -> button offset
*/
$button-offset: 548px;

.presets {
  display: flex;
  flex-direction: column;

  .side-panel {
    .side-panel-container {
      .side-panel-content {
        .preset-list {
          .search-filter-bar {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > .search-box {
              width: 100%;
            }
          }

          .item-selector-container {
            width: 98%;
            height: calc(100vh - #{$item-selector-container-offset});
            margin-top: 12px;

            .search-box {
              margin-bottom: 12px;
            }

            .item-selector-list {
              background-color: white;
              padding: 0px;

              ul li {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }

              li {
                margin-right: 8px;
              }
            }
          }

          .no-items {
            display: block;
            margin-top: 12px;
            color: $text-dark;
          }
        }
      }
    }

    &.expanded {
      width: 392px;
    }
  }

  .presets-data {
    display: flex;
    flex-direction: column;
    margin: 0px 32px 0px 0px;

    width: 100%;
    overflow: auto;

    .tabs-body {
      /*
      * 92px: header height
      * 92px: toolbar height
      * 88px: tabs header height
      * TOTAL = 272 -> tabs-body offset
      */
      height: calc(100vh - 272px);
    }

    section {
      &:not(:last-child) {
        padding-bottom: 4px;
        margin-bottom: 24px;
        border-bottom: 1px solid #cfd5e6;
      }
    }

    .tab-label {
      padding: 8px 0px 8px 12px;
      gap: 10px;
      background: #edeff5;
    }

    .section-container {
      margin-top: 24px;
      padding-left: 12px;

      &:last-child {
        margin-bottom: 55px;
      }
    }

    .section-title {
      h3 {
        font-size: 22px;
        margin-bottom: 24px;
      }

      h4 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 24px;
        color: #2f64f5;
      }
    }
  }

  .device-virtual-list {
    .preset-device-list {
      padding: 0px 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .edit-button-container {
    justify-content: space-between;
    display: flex;
    width: calc(100% - #{$button-offset});
  }

  @media (max-width: 1200px) {
    .presets-data {
      .tabs-body {
        .settings-wrapper {
          width: 100%;
        }
      }
    }
  }
}

.user-modal.modal-dialog {
  max-width: 650px;

  @media (max-width: 992px) {
    max-width: 90%;
  }
}

.create-preset-modal {
  &.modal-dialog {
    max-width: 650px;
  }

  .solution-dropdown,
  .preset-dropdown {
    .dropdown-list-container {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }

  .button-container {
    justify-content: flex-end;
    display: flex;
    button {
      margin-left: 24px;
    }
  }
}
