@import '~@admobilize/ui-kit/variables';

#login-screen .left-area .form-box .login-component {
  .form-group {
    margin-bottom: 30px;

    .btn {
      padding: 0.4rem 1rem;
      font-size: 18px;
    }

    .btn-primary {
      &:disabled, &:disabled:hover {
        color: white;
        background-color: #007BFF;
        border-color: #007BFF;
        cursor: progress;
      }
    }

    & label {
      font-size: 18px;
    }
  }

  .form-control {
    font-size: 14px;
    background: #EDEDED !important;


    &::placeholder {
      color: $brand-slate-blue-medium;
    }

    &:not(.is-invalid) {
      border: 0;
    }
  }

  a {
    color: $brand-slate-blue;
    font-size: 18px;
  }

  .password-input {
    padding-right: 40px;
  }

  .toggle-password {
    display: flex;
    padding: 0px;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    top: 34px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    img {
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
    }
  }

  .invalid-feedback {
    a {
      color: $status-danger;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
