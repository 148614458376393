@import '~@admobilize/ui-kit/variables';
/*
 * 92px: app-header height (with top & bottom padding)
 * 91px: toolbar height (with top & bottom padding)
 * 12px: sites-container with bottom padding
 * 72px: thead hight
 * 46px: pagination-wrapper height with top padding
 */
$table-offset: 313px;

/*
 * 92px: app-header height
 * 91px: toolbar height
 * 12px: sites-container bottom-padding
*/
$page-offset: 195px;

.sites {
  .no-data-message {
    height: calc(100vh - 183px);
  }

  .sites-container {
    padding-right: 24px;
    .side-panel {
      .side-panel-container {
        height: calc(100vh - #{$page-offset});
      }
    }

    .react-table {
      table {
        // th {
        //   padding: 0px 24px;
        // }
        tbody {
          height: calc(100vh - #{$table-offset});

          tr {
            &:hover {
              background-color: #f0f2f7;
            }
          }
        }

        thead tr th,
        tbody tr td {
          &:nth-child(1) {
            //Site name
            width: auto;
            text-align: left;
            padding-left: 24px;
          }
          &:nth-child(2) {
            //Status
            width: 180px;
            div {
              display: flex;
              justify-content: center;
            }
          }
          &:nth-child(3) {
            //Solution
            width: 150px;
            div {
              display: flex;
              justify-content: center;
            }
          }
          &:nth-child(4) {
            //Type
            width: 180px;
            div {
              display: flex;
              justify-content: center;
            }
          }
          &:nth-child(5) {
            //Created
            width: 150px;
            div {
              display: flex;
              justify-content: center;
            }
          }

          .ellipsis {
            flex-wrap: wrap;
            overflow: hidden;

            div {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .pagination-wrapper {
      width: 100%;
      justify-content: center;

      .footer {
        min-width: 280px;
        width: auto;
      }
    }
  }
}

#manage-sites-modal {
  .modal-content {
    padding: 0;

    .modal-body {
      .tabs {
        box-shadow: none;

        .tabs-header {
          .tabs-button-container {
            .btn-tab {
              border-radius: 0;
              padding-bottom: 12px;
              height: auto;
              flex: 1;

              &:first-child {
                border-radius: 5px 0 0 0;
              }

              &:last-child {
                border-radius: 0 5px 0 0;
              }
            }
          }
        }

        .tabs-body {
          padding: 24px 48px;

          h4 {
            font-size: 16px;
            margin: 0;
            color: #767e9a;
          }

          .section-header {
            margin: 0;
            font-weight: 700;
          }
        }
      }
    }

    .modal-footer {
      margin: 0;
      padding: 12px 24px 12px 24px;
      border-top: none;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      .btn-primary {
        flex: 3;
        padding: 12px 24px;
        div {
          justify-content: center;
        }
      }

      .btn-secondary {
        flex: 1;
        padding: 12px 24px 12px 24px;
        div {
          justify-content: center;
        }
      }
    }
  }
}
