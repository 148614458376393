@import '~@admobilize/ui-kit/variables';

#login-screen .left-area .form-box .forgot-password-component {
  .form-group {
    margin-bottom: 42px;

    .btn {
      padding: 0.4rem 1rem;
      font-size: 18px;
    }

    .btn-primary {
      &:disabled, &:disabled:hover {
        color: white;
        background-color: #007BFF;
        border-color: #007BFF;
        cursor: progress;
      }
    }

    & label {
      font-size: 18px;
    }
  }

  .help {
    max-width: 400px;
    color: $brand-slate-blue;
    margin-bottom: 30px;
  }


  .form-control {
    font-size: 14px;
    background-color: #EDEDED;

    &::placeholder {
      color: $brand-slate-blue-medium;
    }

    &:not(.is-invalid) {
      border: 0;
    }
  }

  .success-message {
    max-width: 380px;
  }
}
