.error-message {
  color: #EF5658;
  font-size: 14px;
  margin-left: 12px;
}

.tags-container {
  margin-bottom: 12px;
  display: flex;
  flex-wrap: wrap;

  .tag {
    color: #2F64F5;
    background-color: #E2EDFF;
    margin-bottom: 12px;
    border: none;
    cursor: pointer;
    box-shadow: #1732a90c 0px 3px 6px;

    &:hover {
      background-color: #D3DDED;
    }

    .tag-text {
      color: #2F64F5;
      font-size: 16px;
      font-weight: bold;
    }

    .tag-close {
      background: none;
      color: #2F64F5;
      border-radius: initial;
      font-size: 16px;
      font-weight: bold;
      width: auto;
      height: auto;
    }
  }
}

.project-devices-container {
  .accordion {
    .is-expanded {
      .item-body {
        height: 120px;

        .device-items {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));

          &>div {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}