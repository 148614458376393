.presets-screen {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  .header-title {
    display: flex;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
    }
  }
}