.threshold-range {
  position: relative;
  margin: 0 12px;
  flex-direction: column;

  .custom-range {
    height: 24px;
    opacity: 0;
  }

  .label {
    display: flex;
    align-items: center;
    width: fit-content;
    max-width: 100%;
    height: 28px;
    padding: 4px 8px;
    gap: 8px;
    border-radius: 8px;
    color: #455079;
    background: #EFEFEF;
    font-weight: bold;
    font-size: 16px;
  }

  .virtual-range {
    .bar-bg, .bar-fill {
      background-color: #455079;
      height: 8px;
      width: 100%;
      position: absolute;
      top: 40px;
      border-radius: 5px;
    }

    .bar-fill {
      background: #2f64f5;
    }

    .indicator {
      border: 2px solid #2F64F5;
      padding: 10px;
      background: #EBF1FF;
      border-radius: 50%;
      position: absolute;
      top: 32px;
    }

    &.disabled {
      cursor: not-allowed;

      .bar-fill {
        background-color: #E5E5E5;
      }

      .indicator{
        border: 2px solid #D1D1D1;
        background-color: #E5E5E5;
      }

      .bar-bg {
        background-color: #EFEFEF;
      }
    }
  }

}