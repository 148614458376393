@import '~@admobilize/ui-kit/variables';

.not-found-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100%;

  &.display-logo {
    background: url(../../assets/images/icosahedron.svg) no-repeat top 34px right 34px,
      url(../../assets/images/icosahedron.svg) no-repeat bottom 64px left 64px;
    background-size: 150px 141px, 225px 213px;
  }

  h1, h2 {
    margin-bottom: 20px;
  }

  h1, h2, p {
    color: $text-dark;
  }

  h1 {
    font-size: 7rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  p, a {
    font-size: 1.5rem;
  }
}